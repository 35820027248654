import {
    DeleteDataSource,
    GetDataSource,
    MethodNotImplementedError,
    PutDataSource,
    Query,
} from '@mobilejazz/harmony-core';
import { ApiEndpoint, RouteStopModel } from 'smart-statistics-common';
import { ApiRequestService } from '../services/api-request.service';
import { IdQuery } from "@mobilejazz/harmony-core/dist";

export class RouteStopNetworkDataSource implements GetDataSource<RouteStopModel>, PutDataSource<RouteStopModel>, DeleteDataSource {

    constructor(protected requestService: ApiRequestService) {}

    get(query: Query): Promise<RouteStopModel>;
    get<K>(id: K): Promise<RouteStopModel>;
    get(query): Promise<RouteStopModel> {
        throw new MethodNotImplementedError();
    }

    getAll(query: Query): Promise<RouteStopModel[]>;
    getAll<K>(ids: K[]): Promise<RouteStopModel[]>;
    getAll(query): Promise<RouteStopModel[]> {
        throw new MethodNotImplementedError();
    }

    delete(query: Query): Promise<void>;
    delete<K>(id: K): Promise<void>;
    delete(query): Promise<void> {
        throw new MethodNotImplementedError();
    }

    deleteAll(query: Query): Promise<void>;
    deleteAll<K>(ids: K[]): Promise<void>;
    deleteAll(query): Promise<void> {

        let promisesArray = query.value.stops.map((id) => {
            return this.requestService
                .builder<RouteStopModel>(ApiEndpoint.RouteStopOne)
                .setUrlParameters({
                    operationId: query.value.operationId,
                    routeId: query.value.routeId,
                    stopId: id,
                }).delete().toPromise();
        });

        return Promise.all(promisesArray)
            .then(() => {
                return new Promise<void>((resolve, reject) => resolve());
            })
            .catch(() => {
                return new Promise<void>((resolve, reject) => reject());
            });
    }

    put(value: RouteStopModel, query: Query): Promise<RouteStopModel>;
    put<K>(value: RouteStopModel, id: K): Promise<RouteStopModel>;
    put<K>(value: RouteStopModel, queryOrId: Query | K): Promise<RouteStopModel> {
        throw new MethodNotImplementedError();
    }

    putAll(values: RouteStopModel[], query: Query): Promise<RouteStopModel[]>;
    putAll<K>(values: RouteStopModel[], ids: K[]): Promise<RouteStopModel[]>;
    putAll(values: RouteStopModel[], query): Promise<RouteStopModel[]> {

        let promisesArray = values.map((value) => {
            if (value.id) {
                return this.requestService
                    .builder<RouteStopModel>(ApiEndpoint.RouteStopOne)
                    .setUrlParameters({operationId: query.value.operationId, routeId: query.value.routeId, stopId: value.id})
                    .setResponseConstructor(RouteStopModel)
                    .setBody(value)
                    .put().toPromise();
            }
            return this.requestService
                .builder<RouteStopModel>(ApiEndpoint.RouteStop)
                .setUrlParameters({operationId: query.value.operationId, routeId: query.value.routeId})
                .setResponseConstructor(RouteStopModel)
                .setBody(value)
                .post().toPromise();
        });

        return Promise.all(promisesArray)
            .then((data) => {
                return new Promise<RouteStopModel[]>((resolve, reject) => resolve(data));
            })
            .catch((e) => {
                return new Promise<RouteStopModel[]>((resolve, reject) => reject(e));
            });
    }

}
