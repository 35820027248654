import {
    DefaultOperation,
    DeleteDataSource,
    DeleteRepository,
    GetDataSource,
    GetRepository, JsonDeserializerMapper, JsonSerializerMapper, LocalStorageDataSource, NetworkOperation,
    Operation,
    OperationNotSupportedError,
    PutDataSource,
    PutRepository,
    Query, StorageOperation,
    VoidQuery,
} from '@mobilejazz/harmony-core';
import { TokenModel } from 'smart-statistics-common';

export class TokenRepository implements GetRepository<TokenModel>, PutRepository<TokenModel>, DeleteRepository {

    constructor(
        private readonly getNetworkDataSource: GetDataSource<TokenModel>,
        private readonly putNetworkDataSource: PutDataSource<TokenModel>,
        private readonly deleteNetworkDataSource: DeleteDataSource,
        private readonly localStorageDataSource: LocalStorageDataSource,
        private toOutMapper: JsonDeserializerMapper<string, TokenModel>,
        private toInMapper: JsonSerializerMapper<TokenModel>,
    ) {}

    public get(query: Query, operation?: Operation): Promise<TokenModel>;
    public get<K>(id: K, operation?: Operation): Promise<TokenModel>;
    public async get<K>(queryOrId: Query | K, operation: Operation = new DefaultOperation()): Promise<TokenModel> {
        if ( operation instanceof NetworkOperation || operation instanceof DefaultOperation) {
            return this.getNetworkDataSource.get(queryOrId);
        } else if (operation instanceof StorageOperation) {
            const result: string = await this.localStorageDataSource.get(queryOrId);
            return this.toOutMapper.map(result);
        }
        throw OperationNotSupportedError;
    }

    public getAll(query: Query, operation?: Operation): Promise<TokenModel[]>;
    public getAll<K>(ids: K[], operation?: Operation): Promise<TokenModel[]>;
    public async getAll<K>(queryOrId: Query | K[], operation: Operation = new DefaultOperation()): Promise<TokenModel[]> {
        if ( operation instanceof NetworkOperation || operation instanceof DefaultOperation) {
            return this.getNetworkDataSource.getAll(queryOrId);
        } else if (operation instanceof StorageOperation) {
            const results: string[] = await this.localStorageDataSource.getAll(queryOrId);
            return results.map((r: string) => this.toOutMapper.map(r));
        }
        throw OperationNotSupportedError;
    }

    public put(value: TokenModel, query?: Query, operation?: Operation): Promise<TokenModel>;
    public put<K>(value: TokenModel, id?: K, operation?: Operation): Promise<TokenModel>;
    public async put<K>(
        value: TokenModel,
        queryOrId: Query | K = new VoidQuery(),
        operation: Operation = new DefaultOperation(),
    ): Promise<TokenModel> {
        if ( operation instanceof NetworkOperation || operation instanceof DefaultOperation) {
            return this.putNetworkDataSource.put(value, queryOrId);
        } else if (operation instanceof StorageOperation) {
            let mapped: string  = this.toInMapper.map(value);
            let result: string = await this.localStorageDataSource.put(mapped, queryOrId);
            return this.toOutMapper.map(result);
        }
        throw OperationNotSupportedError;
    }

    public putAll(values: TokenModel[], query?: Query, operation?: Operation): Promise<TokenModel[]>;
    public putAll<K>(values: TokenModel[], ids?: K[], operation?: Operation): Promise<TokenModel[]>;
    public async putAll<K>(
        values: TokenModel[],
        queryOrIds: Query | K[] = new VoidQuery(),
        operation: Operation = new DefaultOperation(),
    ): Promise<TokenModel[]> {
        if ( operation instanceof NetworkOperation || operation instanceof DefaultOperation) {
            return this.putNetworkDataSource.putAll(values, queryOrIds);
        } else if (operation instanceof StorageOperation) {
            let mapped: string[]  = this.toInMapper.map(values);
            let results: string[] = await this.localStorageDataSource.putAll(mapped, queryOrIds);
            return results.map((r: string) => this.toOutMapper.map(r));
        }
        throw OperationNotSupportedError;
    }

    public delete(query: Query, operation: Operation): Promise<void>;
    public delete<K>(id: K, operation: Operation): Promise<void>;
    public async delete<K>(queryOrId: Query | K, operation: Operation): Promise<void> {
        if ( operation instanceof NetworkOperation || operation instanceof DefaultOperation) {
            return this.deleteNetworkDataSource.delete(queryOrId);
        } else if (operation instanceof StorageOperation) {
            return this.localStorageDataSource.delete(queryOrId);
        }

        throw OperationNotSupportedError;
    }

    public deleteAll(query: Query, operation: Operation): Promise<void>;
    public deleteAll<K>(ids: K[], operation: Operation): Promise<void>;
    public async deleteAll<K>(queryOrIds: Query | K[], operation: Operation): Promise<void> {
        if ( operation instanceof NetworkOperation || operation instanceof DefaultOperation) {
            return this.deleteNetworkDataSource.deleteAll(queryOrIds);
        } else if (operation instanceof StorageOperation) {
            return this.localStorageDataSource.deleteAll(queryOrIds);
        }
        throw OperationNotSupportedError;
    }

}
