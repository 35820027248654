import * as tslib_1 from "tslib";
import { KeyQuery, ObjectQuery, StorageOperation } from '@mobilejazz/harmony-core';
var LoginInteractor = /** @class */ (function () {
    function LoginInteractor(putInteractor) {
        this.putInteractor = putInteractor;
    }
    LoginInteractor.prototype.execute = function (email, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.putInteractor.execute(null, new ObjectQuery({
                            email: email,
                            password: password,
                        }))];
                    case 1:
                        token = _a.sent();
                        if (token) {
                            this.putInteractor.execute(token, new KeyQuery('token'), new StorageOperation());
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return LoginInteractor;
}());
export { LoginInteractor };
