import {
    DeleteDataSource,
    GetDataSource,
    MethodNotImplementedError,
    PutDataSource,
    Query,
    QueryNotSupportedError,
} from '@mobilejazz/harmony-core';
import { IdQuery } from '@mobilejazz/harmony-core/dist';
import { ApiEndpoint, ClientModel, OperationModel } from 'smart-statistics-common';
import { ApiRequestService } from '../services/api-request.service';

export class ClientNetworkDataSource implements GetDataSource<ClientModel>, PutDataSource<ClientModel>, DeleteDataSource {

    constructor(protected requestService: ApiRequestService) {}

    get(query: Query): Promise<ClientModel>;
    get<K>(id: K): Promise<ClientModel>;
    get<K>(queryOrId: Query | K): Promise<ClientModel> {

        let request = this.requestService
            .builder<ClientModel>(ApiEndpoint.ClientOne)
            .setResponseConstructor(ClientModel);

        if (queryOrId instanceof Query) {
            throw new QueryNotSupportedError();
        } else if (typeof queryOrId === 'string' || typeof queryOrId === 'number' ) {
            request = request
            .setUrlParameters({clientId: queryOrId});
        } else {
            throw new QueryNotSupportedError();
        }

        return request.get().toPromise();
    }

    getAll(query: Query): Promise<ClientModel[]>;
    getAll<K>(ids: K[]): Promise<ClientModel[]>;
    getAll(query): Promise<ClientModel[]> {
        return this.requestService
            .builder<ClientModel[]>(ApiEndpoint.Client)
            .setResponseConstructor(ClientModel)
            .get().toPromise();
    }

    delete(query: Query): Promise<void>;
    delete<K>(id: K): Promise<void>;
    delete<K>(queryOrId: Query | K): Promise<void> {
        if (queryOrId instanceof IdQuery) {
            this.requestService
            .builder<OperationModel>(ApiEndpoint.ClientOne)
            .setUrlParameters({clientId: queryOrId.id})
            .delete().toPromise();
        } else {
            throw new QueryNotSupportedError();
        }
        return;
    }

    deleteAll(query: Query): Promise<void>;
    deleteAll<K>(ids: K[]): Promise<void>;
    deleteAll(query): Promise<void> {
        throw new MethodNotImplementedError();
    }

    put(value: ClientModel, query: Query): Promise<ClientModel>;
    put<K>(value: ClientModel, id: K): Promise<ClientModel>;
    put(value: ClientModel, query): Promise<ClientModel> {

        if (value.id) {
            return this.requestService
                .builder<ClientModel>(ApiEndpoint.ClientOne)
                .setUrlParameters({clientId: value.id})
                .setResponseConstructor(ClientModel)
                .setBody(value).put().toPromise();
        }
        return this.requestService
            .builder<ClientModel>(ApiEndpoint.Client)
            .setResponseConstructor(ClientModel)
            .setBody(value).post().toPromise();
    }

    putAll(values: ClientModel[], query: Query): Promise<ClientModel[]>;
    putAll<K>(values: ClientModel[], ids: K[]): Promise<ClientModel[]>;
    putAll(values: ClientModel[], query): Promise<ClientModel[]> {
        throw new MethodNotImplementedError();
    }

}
