import { Inject, Injectable } from '@angular/core';
import { DeleteInteractor, GetAllInteractor, GetInteractor, PutInteractor } from '@mobilejazz/harmony-core';
import { IdQuery, ObjectQuery } from '@mobilejazz/harmony-core/dist';
import { ChannelModel } from 'smart-statistics-common';

@Injectable()
export class ChannelService {

    constructor(
        @Inject('GetInteractor<ChannelModel>')
        protected getChannelInteractor: GetInteractor<ChannelModel>,
        @Inject('GetAllInteractor<ChannelModel>')
        protected getAllChannelInteractor: GetAllInteractor<ChannelModel>,
        @Inject('PutInteractor<ChannelModel>')
        protected putChannelInteractor: PutInteractor<ChannelModel>,
        @Inject('DeleteInteractor<ChannelModel>')
        protected deleteChannelInteractor: DeleteInteractor,
    ) {}

    public getAll(operationId: number): Promise<ChannelModel[]> {
        return this.getAllChannelInteractor.execute(new ObjectQuery({operationId: operationId}));
    }

    public put(channel: ChannelModel, operationId: number): Promise<ChannelModel> {
        return this.putChannelInteractor.execute(channel, new IdQuery(operationId));
    }

    public delete(channel: ChannelModel, operationId: number): Promise<void> {
        return this.deleteChannelInteractor.execute(new ObjectQuery({operationId: operationId, channelId: channel.id}));
    }

}
