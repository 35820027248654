import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { GetInteractor, IdQuery, StorageOperation } from '@mobilejazz/harmony-core';
import {EMPTY, from, Observable, throwError} from 'rxjs';
import 'rxjs/add/operator/map';
import {catchError, mergeMap} from 'rxjs/operators';
import {ApiEndpoint, TokenModel} from 'smart-statistics-common';
import {Router} from "@angular/router";

@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {
    constructor(
        @Inject('GetInteractor<TokenModel>')
        private tokenInteractor: GetInteractor<TokenModel>,
        private router: Router,
    ) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.url.indexOf(ApiEndpoint.PublicAuthLogin) > -1) {
            return next.handle(request);
        }

        return from(this.tokenInteractor.execute(new IdQuery('token'), new StorageOperation()))
            .pipe(mergeMap(
                (token: TokenModel) => {
                    if (token) {
                        request = request.clone({
                            setHeaders: {
                                Authorization: `Bearer ${token.token}`,
                            },
                        });
                    }
                    return next.handle(request).pipe(
                        catchError((error: HttpErrorResponse) => {
                          if (error.status === 401) {
                              this.router.navigateByUrl('/logout');
                              return EMPTY;
                          } else {
                              return throwError(error);
                          }
                      }),
                    );
                },
            ));
    }
}
