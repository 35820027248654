export const locale = {
    lang: 'en',
    data: {
        NAV: {
            ADMIN: {
                TITLE: 'ADMINISTRATION',
                ADMINISTRATORS: 'Administrators',
                CLIENTS: 'Clients',
                HARDWAREIDENTIFIERS: 'Hardware Ids',
            },
            OP_ASSETS: {
                TITLE: 'OPERATION ASSETS',
                VEHICLES: 'Vehicles',
                ROUTES: 'Routes',
                CHANNELS: 'Channels',
            },
            STATISTICS: {
                TITLE: 'Statistics',
            },
            SETTINGS: {
                TITLE: 'Settings',
                MANAGE_OP: 'Manage Operations',
                MANAGE_USERS: 'Manage Users',
            },
        },
    },
};
