import { FuseNavigation } from '@fuse/types';

export const adminNavigation: FuseNavigation[] = [
    {
        id       : 'admin',
        title    : 'Administration',
        translate: 'NAV.ADMIN.TITLE',
        type     : 'group',
        children : [
            {
                id       : 'clients',
                title    : 'Clients',
                translate: 'NAV.ADMIN.CLIENTS',
                type     : 'item',
                icon     : 'work',
                url      : '/private/admin/clients',
            },
            {
                id       : 'hardware-identifiers',
                title    : 'Hardware Identifiers',
                translate: 'NAV.ADMIN.HARDWAREIDENTIFIERS',
                type     : 'item',
                icon     : 'router',
                url      : '/private/admin/hardware-identifiers',
            },
            {
                id       : 'administrators',
                title    : 'Administrators',
                translate: 'NAV.ADMIN.ADMINISTRATORS',
                type     : 'item',
                icon     : 'people  ',
                url      : '/private/admin/administrators',
            },
        ],
    },
];

export const navigation: FuseNavigation[] = [
    {
        id       : 'op_assets',
        title    : 'Operation Assets',
        translate: 'NAV.OP_ASSETS.TITLE',
        type     : 'group',
        children : [
            {
                id       : 'vehicles',
                title    : 'Vehicles',
                translate: 'NAV.OP_ASSETS.VEHICLES',
                type     : 'item',
                icon     : 'directions_bus',
                url      : '/private/client/:clientId/assets/vehicles',
            },
            {
                id       : 'routes',
                title    : 'Routes',
                translate: 'NAV.OP_ASSETS.ROUTES',
                type     : 'item',
                icon     : 'map',
                url      : '/private/client/:clientId/assets/routes',
            },
            {
                id       : 'channels',
                title    : 'Channels',
                translate: 'NAV.OP_ASSETS.CHANNELS',
                type     : 'item',
                icon     : 'flag',
                url      : '/private/client/:clientId/assets/channels',
            },
        ],
    }, {
        id       : 'stats',
        title    : 'Statistics',
        translate: 'NAV.STATISTICS.TITLE',
        type     : 'group',
        children : [
            {
                id       : 'statistics',
                title    : 'Statistics',
                translate: 'NAV.STATISTICS.TITLE',
                type     : 'item',
                icon     : 'dashboard',
                url      : '/private/client/:clientId/statistics/dashboard',
            },
        ],
    }, {
        id       : 'settings',
        title    : 'Settings',
        translate: 'NAV.SETTINGS.TITLE',
        type     : 'group',
        children : [
            {
                id       : 'manage_op',
                title    : 'Manage Operations',
                translate: 'NAV.SETTINGS.MANAGE_OP',
                type     : 'item',
                icon     : 'location_city',
                url      : '/private/client/:clientId/settings/operations',
            },
            {
                id       : 'manage_users',
                title    : 'Manage Users',
                translate: 'NAV.SETTINGS.MANAGE_USERS',
                type     : 'item',
                icon     : 'people',
                url      : '/private/client/:clientId/settings/users',
            },
        ],
    },
];
