import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpRequestBuilder } from '@mobilejazz/harmony-angular';

@Injectable()
export class ApiRequestService {
    constructor(
        @Inject('API_BASE_URL')
        private readonly baseUrl: string,
        @Inject(HttpClient)
        private readonly http: HttpClient,
    ) {}

    public builder<T>(endpoint: string) {
       return new HttpRequestBuilder<T>(this.baseUrl + endpoint, this.http);
    }
}
