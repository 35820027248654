import {
    DeleteDataSource,
    GetDataSource,
    MethodNotImplementedError,
    PutDataSource,
    Query,
} from '@mobilejazz/harmony-core';
import { ApiEndpoint, LanguageModel } from 'smart-statistics-common';
import { ApiRequestService } from '../services/api-request.service';

export class LanguageNetworkDataSource implements GetDataSource<LanguageModel>, PutDataSource<LanguageModel>, DeleteDataSource {

    constructor(protected requestService: ApiRequestService) {}

    get(query: Query): Promise<LanguageModel>;
    get<K>(id: K): Promise<LanguageModel>;
    get(query): Promise<LanguageModel> {
        throw new MethodNotImplementedError();
    }

    getAll(query: Query): Promise<LanguageModel[]>;
    getAll<K>(ids: K[]): Promise<LanguageModel[]>;
    getAll(query): Promise<LanguageModel[]> {
        return this.requestService
            .builder<LanguageModel[]>(ApiEndpoint.Language)
            .setResponseConstructor(LanguageModel)
            .get().toPromise();
    }

    delete(query: Query): Promise<void>;
    delete<K>(id: K): Promise<void>;
    delete(query): Promise<void> {
        throw new MethodNotImplementedError();
    }

    deleteAll(query: Query): Promise<void>;
    deleteAll<K>(ids: K[]): Promise<void>;
    deleteAll(query): Promise<void> {
        throw new MethodNotImplementedError();
    }

    put(value: LanguageModel, query: Query): Promise<LanguageModel>;
    put<K>(value: LanguageModel, id: K): Promise<LanguageModel>;
    put(value: LanguageModel, query): Promise<LanguageModel> {
        throw new MethodNotImplementedError();
    }

    putAll(values: LanguageModel[], query: Query): Promise<LanguageModel[]>;
    putAll<K>(values: LanguageModel[], ids: K[]): Promise<LanguageModel[]>;
    putAll(values: LanguageModel[], query): Promise<LanguageModel[]> {
        throw new MethodNotImplementedError();
    }

}
