import {
    DeleteDataSource,
    GetDataSource,
    MethodNotImplementedError,
    PutDataSource,
    Query,
} from '@mobilejazz/harmony-core';
import { ApiEndpoint, OperationModel } from 'smart-statistics-common';
import { ApiRequestService } from '../services/api-request.service';

export class OperationNetworkDataSource implements GetDataSource<OperationModel>, PutDataSource<OperationModel>, DeleteDataSource {

    constructor(protected requestService: ApiRequestService) {}

    get(query: Query): Promise<OperationModel>;
    get<K>(id: K): Promise<OperationModel>;
    get(query): Promise<OperationModel> {
        throw new MethodNotImplementedError();
    }

    getAll(query: Query): Promise<OperationModel[]>;
    getAll<K>(ids: K[]): Promise<OperationModel[]>;
    getAll(query): Promise<OperationModel[]> {
        return this.requestService
            .builder<OperationModel[]>(ApiEndpoint.Operation)
            .setResponseConstructor(OperationModel)
            .setUrlParameters({clientId: query.value.clientId})
            .get().toPromise();
    }

    delete(query: Query): Promise<void>;
    delete<K>(id: K): Promise<void>;
    delete(query): Promise<void> {
        return this.requestService
            .builder<OperationModel>(ApiEndpoint.OperationOne)
            .setUrlParameters({clientId: query.value.clientId, operationId: query.value.operationId})
            .delete().toPromise();
    }

    deleteAll(query: Query): Promise<void>;
    deleteAll<K>(ids: K[]): Promise<void>;
    deleteAll(query): Promise<void> {
        throw new MethodNotImplementedError();
    }

    put(value: OperationModel, query: Query): Promise<OperationModel>;
    put<K>(value: OperationModel, id: K): Promise<OperationModel>;
    put(value: OperationModel, query): Promise<OperationModel> {
        if (value.id) {
            return this.requestService
                .builder<OperationModel>(ApiEndpoint.OperationOne)
                .setUrlParameters({clientId: value.client.id, operationId: value.id})
                .setResponseConstructor(OperationModel)
                .setBody(value)
                .put().toPromise();
        }
        return this.requestService
            .builder<OperationModel>(ApiEndpoint.Operation)
            .setUrlParameters({clientId: value.client.id})
            .setResponseConstructor(OperationModel)
            .setBody(value)
            .post().toPromise();
    }

    putAll(values: OperationModel[], query: Query): Promise<OperationModel[]>;
    putAll<K>(values: OperationModel[], ids: K[]): Promise<OperationModel[]>;
    putAll(values: OperationModel[], query): Promise<OperationModel[]> {
        throw new MethodNotImplementedError();
    }

}
