import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';

import {FuseModule} from '@fuse/fuse.module';
import {TranslateModule} from '@ngx-translate/core';

import {AppComponent} from 'app/app.component';

import {fuseConfig} from 'app/fuse-config';
import 'hammerjs';
import {AppRoutingModule} from './app-routing.module';
import { ContextService } from './shared/services/context.service';

import {SharedModule} from './shared/shared.module';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports     : [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot(),

        SharedModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
    ],
    exports     : [
        RouterModule,
    ],
    providers   : [
        ContextService,
    ],
    bootstrap   : [
        AppComponent,
    ],
})
export class AppModule {
}
