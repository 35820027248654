import { Inject, Injectable } from '@angular/core';
import { GetAllInteractor } from '@mobilejazz/harmony-core';
import { LanguageModel } from 'smart-statistics-common';

@Injectable()
export class LanguageService {

    constructor(
        @Inject('GetAllInteractor<LanguageModel>')
        protected getAllLanguageInteractor: GetAllInteractor<LanguageModel>,
    ) {}

    public getAll(): Promise<LanguageModel[]> {
        return this.getAllLanguageInteractor.execute();
    }

}
