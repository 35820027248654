import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DeleteAllInteractor, DeleteInteractor, GetAllInteractor, GetInteractor, JsonDeserializerMapper, JsonSerializerMapper, LocalStorageDataSource, PutAllInteractor, PutInteractor, SingleDataSourceRepository, } from '@mobilejazz/harmony-core';
import { SingleGetDataSourceRepository } from '@mobilejazz/harmony-core/dist';
import { TokenModel, } from 'smart-statistics-common';
import { FuseSharedModule } from '../../@fuse/shared.module';
import { environment } from '../../environments/environment';
import { ChannelNetworkDataSource } from "./data-sources/channel-network.data-source";
import { ClientNetworkDataSource } from './data-sources/client-network.data-source';
import { HardwareIdentifierNetworkDataSource } from './data-sources/hardware-identifier-network.data-source';
import { LanguageNetworkDataSource } from "./data-sources/language-network.data-source";
import { OperationNetworkDataSource } from "./data-sources/operation-network.data-source";
import { RouteNetworkDataSource } from "./data-sources/route-network.data-source";
import { RouteStopNetworkDataSource } from "./data-sources/route-stop-network.data-source";
import { StatisticResultNetworkDataSource } from './data-sources/statistic-result-network.data-source';
import { TokenNetworkDataSource } from './data-sources/token-network.data-source';
import { UserNetworkDataSource } from './data-sources/user-network.data-source';
import { VehicleNetworkDataSource } from "./data-sources/vehicle-network.data-source";
import { LoginInteractor } from './interactors/login.interactor';
import { JwtTokenInterceptor } from './interceptors/jwt-token.interceptor';
import { MaterialLibraryModule } from './material/material-library.module';
import { TokenRepository } from './repository/token.repository';
import { AdminService } from "./services/admin.service";
import { ApiRequestService } from './services/api-request.service';
import { AuthService } from './services/auth.service';
import { ChannelService } from "./services/channel.service";
import { ClientService } from "./services/client.service";
import { HardwareIdentifierService } from './services/hardware-identifier.service';
import { LanguageService } from "./services/language.service";
import { OperationService } from "./services/operation.service";
import { RouteStopService } from "./services/route-stop.service";
import { RouteService } from "./services/route.service";
import { StatisticsService } from "./services/statistics.service";
import { UserService } from './services/user.service';
import { VehicleService } from "./services/vehicle.service";
var SHARED_MODULES = [
    FuseSharedModule,
    MaterialLibraryModule,
];
var SHARED_COMPONENTS = [];
var SHARED_ENTRY_COMPONENTS = [];
var ɵ0 = environment.api.baseUrl, ɵ1 = function (repository) {
    return new GetInteractor(repository);
}, ɵ2 = function (repository) {
    return new GetAllInteractor(repository);
}, ɵ3 = function (repository) {
    return new PutInteractor(repository);
}, ɵ4 = function (repository) {
    return new DeleteInteractor(repository);
}, ɵ5 = function (repository) {
    return new GetAllInteractor(repository);
}, ɵ6 = function (repository) {
    return new GetInteractor(repository);
}, ɵ7 = function (repository) {
    return new GetAllInteractor(repository);
}, ɵ8 = function (repository) {
    return new PutInteractor(repository);
}, ɵ9 = function (repository) {
    return new DeleteInteractor(repository);
}, ɵ10 = function (repository) {
    return new GetInteractor(repository);
}, ɵ11 = function (repository) {
    return new GetAllInteractor(repository);
}, ɵ12 = function (repository) {
    return new PutInteractor(repository);
}, ɵ13 = function (repository) {
    return new DeleteInteractor(repository);
}, ɵ14 = function (repository) {
    return new GetInteractor(repository);
}, ɵ15 = function (repository) {
    return new GetAllInteractor(repository);
}, ɵ16 = function (repository) {
    return new PutInteractor(repository);
}, ɵ17 = function (repository) {
    return new DeleteInteractor(repository);
}, ɵ18 = function (repository) {
    return new GetInteractor(repository);
}, ɵ19 = function (repository) {
    return new GetAllInteractor(repository);
}, ɵ20 = function (repository) {
    return new PutInteractor(repository);
}, ɵ21 = function (repository) {
    return new DeleteInteractor(repository);
}, ɵ22 = function (repository) {
    return new GetInteractor(repository);
}, ɵ23 = function (repository) {
    return new GetAllInteractor(repository);
}, ɵ24 = function (repository) {
    return new PutInteractor(repository);
}, ɵ25 = function (repository) {
    return new DeleteInteractor(repository);
}, ɵ26 = function (repository) {
    return new PutAllInteractor(repository);
}, ɵ27 = function (repository) {
    return new DeleteAllInteractor(repository);
}, ɵ28 = function (repository) {
    return new GetAllInteractor(repository);
}, ɵ29 = function (repository) {
    return new GetInteractor(repository);
}, ɵ30 = function (repository) {
    return new PutInteractor(repository);
}, ɵ31 = function (repository) {
    return new DeleteInteractor(repository);
}, ɵ32 = function (putInteractor) {
    return new LoginInteractor(putInteractor);
}, ɵ33 = function (repository) {
    return new GetInteractor(repository);
}, ɵ34 = function (repository) {
    return new GetAllInteractor(repository);
}, ɵ35 = function (repository) {
    return new PutInteractor(repository);
}, ɵ36 = function (repository) {
    return new DeleteInteractor(repository);
}, ɵ37 = function (dataSource) {
    return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
}, ɵ38 = function (dataSource) {
    return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
}, ɵ39 = function (dataSource) {
    return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
}, ɵ40 = function (dataSource) {
    return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
}, ɵ41 = function (dataSource) {
    return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
}, ɵ42 = function (dataSource) {
    return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
}, ɵ43 = function (dataSource) {
    return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
}, ɵ44 = function (dataSource) {
    return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
}, ɵ45 = function (dataSource) {
    return new SingleGetDataSourceRepository(dataSource);
}, ɵ46 = function (dataSource) {
    return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
}, ɵ47 = function (tokenNetworkDataSource, localStorageDataSource) {
    var toOutMapper = new JsonDeserializerMapper(TokenModel);
    var toInMapper = new JsonSerializerMapper();
    return new TokenRepository(tokenNetworkDataSource, tokenNetworkDataSource, tokenNetworkDataSource, localStorageDataSource, toOutMapper, toInMapper);
}, ɵ48 = function (dataSource) {
    return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
}, ɵ49 = function (requestService) {
    return new ChannelNetworkDataSource(requestService);
}, ɵ50 = function (requestService) {
    return new ClientNetworkDataSource(requestService);
}, ɵ51 = function (requestService) {
    return new HardwareIdentifierNetworkDataSource(requestService);
}, ɵ52 = function (requestService) {
    return new LanguageNetworkDataSource(requestService);
}, ɵ53 = function (requestService) {
    return new OperationNetworkDataSource(requestService);
}, ɵ54 = function (requestService) {
    return new RouteNetworkDataSource(requestService);
}, ɵ55 = function (requestService) {
    return new RouteStopNetworkDataSource(requestService);
}, ɵ56 = function (requestService) {
    return new UserNetworkDataSource(requestService);
}, ɵ57 = function (requestService) {
    return new StatisticResultNetworkDataSource(requestService);
}, ɵ58 = function (requestService) {
    return new TokenNetworkDataSource(requestService);
}, ɵ59 = function (requestService) {
    return new VehicleNetworkDataSource(requestService);
};
var SHARED_PROVIDERS = [
    // CONSTANTS
    {
        provide: 'API_BASE_URL',
        useValue: ɵ0,
    },
    // SERVICES
    StatisticsService,
    ApiRequestService,
    AdminService,
    AuthService,
    ChannelService,
    ClientService,
    HardwareIdentifierService,
    LanguageService,
    OperationService,
    RouteService,
    RouteStopService,
    UserService,
    VehicleService,
    // INTERACTORS
    // For UserModel
    {
        provide: 'GetInteractor<UserModel>',
        useFactory: ɵ1,
        deps: ['SingleDataSourceRepository<UserModel>'],
    },
    {
        provide: 'GetAllInteractor<UserModel>',
        useFactory: ɵ2,
        deps: ['SingleDataSourceRepository<UserModel>'],
    },
    {
        provide: 'PutInteractor<UserModel>',
        useFactory: ɵ3,
        deps: ['SingleDataSourceRepository<UserModel>'],
    },
    {
        provide: 'DeleteInteractor<UserModel>',
        useFactory: ɵ4,
        deps: ['SingleDataSourceRepository<UserModel>'],
    },
    // For LanguageModel
    {
        provide: 'GetAllInteractor<LanguageModel>',
        useFactory: ɵ5,
        deps: ['SingleDataSourceRepository<LanguageModel>'],
    },
    // For ChannelModel
    {
        provide: 'GetInteractor<ChannelModel>',
        useFactory: ɵ6,
        deps: ['SingleDataSourceRepository<ChannelModel>'],
    },
    {
        provide: 'GetAllInteractor<ChannelModel>',
        useFactory: ɵ7,
        deps: ['SingleDataSourceRepository<ChannelModel>'],
    },
    {
        provide: 'PutInteractor<ChannelModel>',
        useFactory: ɵ8,
        deps: ['SingleDataSourceRepository<ChannelModel>'],
    },
    {
        provide: 'DeleteInteractor<ChannelModel>',
        useFactory: ɵ9,
        deps: ['SingleDataSourceRepository<ChannelModel>'],
    },
    // For ClientModel
    {
        provide: 'GetInteractor<ClientModel>',
        useFactory: ɵ10,
        deps: ['SingleDataSourceRepository<ClientModel>'],
    },
    {
        provide: 'GetAllInteractor<ClientModel>',
        useFactory: ɵ11,
        deps: ['SingleDataSourceRepository<ClientModel>'],
    },
    {
        provide: 'PutInteractor<ClientModel>',
        useFactory: ɵ12,
        deps: ['SingleDataSourceRepository<ClientModel>'],
    },
    {
        provide: 'DeleteInteractor<ClientModel>',
        useFactory: ɵ13,
        deps: ['SingleDataSourceRepository<ClientModel>'],
    },
    // For HardwareIdentifierModel
    {
        provide: 'GetInteractor<HardwareIdentifierModel>',
        useFactory: ɵ14,
        deps: ['SingleDataSourceRepository<HardwareIdentifierModel>'],
    },
    {
        provide: 'GetAllInteractor<HardwareIdentifierModel>',
        useFactory: ɵ15,
        deps: ['SingleDataSourceRepository<HardwareIdentifierModel>'],
    },
    {
        provide: 'PutInteractor<HardwareIdentifierModel>',
        useFactory: ɵ16,
        deps: ['SingleDataSourceRepository<HardwareIdentifierModel>'],
    },
    {
        provide: 'DeleteInteractor<HardwareIdentifierModel>',
        useFactory: ɵ17,
        deps: ['SingleDataSourceRepository<HardwareIdentifierModel>'],
    },
    // For OperationModel
    {
        provide: 'GetInteractor<OperationModel>',
        useFactory: ɵ18,
        deps: ['SingleDataSourceRepository<OperationModel>'],
    },
    {
        provide: 'GetAllInteractor<OperationModel>',
        useFactory: ɵ19,
        deps: ['SingleDataSourceRepository<OperationModel>'],
    },
    {
        provide: 'PutInteractor<OperationModel>',
        useFactory: ɵ20,
        deps: ['SingleDataSourceRepository<OperationModel>'],
    },
    {
        provide: 'DeleteInteractor<OperationModel>',
        useFactory: ɵ21,
        deps: ['SingleDataSourceRepository<OperationModel>'],
    },
    // For RouteModel
    {
        provide: 'GetInteractor<RouteModel>',
        useFactory: ɵ22,
        deps: ['SingleDataSourceRepository<RouteModel>'],
    },
    {
        provide: 'GetAllInteractor<RouteModel>',
        useFactory: ɵ23,
        deps: ['SingleDataSourceRepository<RouteModel>'],
    },
    {
        provide: 'PutInteractor<RouteModel>',
        useFactory: ɵ24,
        deps: ['SingleDataSourceRepository<RouteModel>'],
    },
    {
        provide: 'DeleteInteractor<RouteModel>',
        useFactory: ɵ25,
        deps: ['SingleDataSourceRepository<RouteModel>'],
    },
    // For RouteStopModel
    {
        provide: 'PutAllInteractor<RouteStopModel>',
        useFactory: ɵ26,
        deps: ['SingleDataSourceRepository<RouteStopModel>'],
    },
    {
        provide: 'DeleteAllInteractor<RouteStopModel>',
        useFactory: ɵ27,
        deps: ['SingleDataSourceRepository<RouteStopModel>'],
    },
    // For StatisticResultModel
    {
        provide: 'GetAllInteractor<StatisticResultModel>',
        useFactory: ɵ28,
        deps: ['SingleGetDataSourceRepository<StatisticResultModel>'],
    },
    // For TokenModel
    {
        provide: 'GetInteractor<TokenModel>',
        useFactory: ɵ29,
        deps: ['TokenRepository'],
    },
    {
        provide: 'PutInteractor<TokenModel>',
        useFactory: ɵ30,
        deps: ['TokenRepository'],
    },
    {
        provide: 'DeleteInteractor<TokenModel>',
        useFactory: ɵ31,
        deps: ['TokenRepository'],
    },
    {
        provide: 'LoginInteractor',
        useFactory: ɵ32,
        deps: ['PutInteractor<TokenModel>'],
    },
    // For VehicleModel
    {
        provide: 'GetInteractor<VehicleModel>',
        useFactory: ɵ33,
        deps: ['SingleDataSourceRepository<VehicleModel>'],
    },
    {
        provide: 'GetAllInteractor<VehicleModel>',
        useFactory: ɵ34,
        deps: ['SingleDataSourceRepository<VehicleModel>'],
    },
    {
        provide: 'PutInteractor<VehicleModel>',
        useFactory: ɵ35,
        deps: ['SingleDataSourceRepository<VehicleModel>'],
    },
    {
        provide: 'DeleteInteractor<VehicleModel>',
        useFactory: ɵ36,
        deps: ['SingleDataSourceRepository<VehicleModel>'],
    },
    // REPOSITORIES
    {
        provide: 'SingleDataSourceRepository<ChannelModel>',
        useFactory: ɵ37,
        deps: ['ChannelNetworkDataSource'],
    },
    {
        provide: 'SingleDataSourceRepository<ClientModel>',
        useFactory: ɵ38,
        deps: ['ClientNetworkDataSource'],
    },
    {
        provide: 'SingleDataSourceRepository<HardwareIdentifierModel>',
        useFactory: ɵ39,
        deps: ['HardwareIdentifierNetworkDataSource'],
    },
    {
        provide: 'SingleDataSourceRepository<LanguageModel>',
        useFactory: ɵ40,
        deps: ['LanguageNetworkDataSource'],
    },
    {
        provide: 'SingleDataSourceRepository<OperationModel>',
        useFactory: ɵ41,
        deps: ['OperationNetworkDataSource'],
    },
    {
        provide: 'SingleDataSourceRepository<RouteModel>',
        useFactory: ɵ42,
        deps: ['RouteNetworkDataSource'],
    },
    {
        provide: 'SingleDataSourceRepository<RouteStopModel>',
        useFactory: ɵ43,
        deps: ['RouteStopNetworkDataSource'],
    },
    {
        provide: 'SingleDataSourceRepository<UserModel>',
        useFactory: ɵ44,
        deps: ['UserNetworkDataSource'],
    },
    {
        provide: 'SingleGetDataSourceRepository<StatisticResultModel>',
        useFactory: ɵ45,
        deps: ['StatisticResultNetworkDataSource'],
    },
    {
        provide: 'SingleDataSourceRepository<VehicleModel>',
        useFactory: ɵ46,
        deps: ['VehicleNetworkDataSource'],
    },
    {
        provide: 'TokenRepository',
        useFactory: ɵ47,
        deps: ['TokenNetworkDataSource', LocalStorageDataSource],
    },
    {
        provide: 'SingleDataSourceRepository<TokenModel>',
        useFactory: ɵ48,
        deps: ['TokenNetworkDataSource'],
    },
    // DATA SOURCES
    {
        provide: 'ChannelNetworkDataSource',
        useFactory: ɵ49,
        deps: [ApiRequestService],
    },
    {
        provide: 'ClientNetworkDataSource',
        useFactory: ɵ50,
        deps: [ApiRequestService],
    },
    {
        provide: 'HardwareIdentifierNetworkDataSource',
        useFactory: ɵ51,
        deps: [ApiRequestService],
    },
    {
        provide: 'LanguageNetworkDataSource',
        useFactory: ɵ52,
        deps: [ApiRequestService],
    },
    {
        provide: 'OperationNetworkDataSource',
        useFactory: ɵ53,
        deps: [ApiRequestService],
    },
    {
        provide: 'RouteNetworkDataSource',
        useFactory: ɵ54,
        deps: [ApiRequestService],
    },
    {
        provide: 'RouteStopNetworkDataSource',
        useFactory: ɵ55,
        deps: [ApiRequestService],
    },
    {
        provide: 'UserNetworkDataSource',
        useFactory: ɵ56,
        deps: [ApiRequestService],
    },
    {
        provide: 'StatisticResultNetworkDataSource',
        useFactory: ɵ57,
        deps: [ApiRequestService],
    },
    {
        provide: 'TokenNetworkDataSource',
        useFactory: ɵ58,
        deps: [ApiRequestService],
    },
    {
        provide: 'VehicleNetworkDataSource',
        useFactory: ɵ59,
        deps: [ApiRequestService],
    },
    LocalStorageDataSource,
    // INTERCEPTORS
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtTokenInterceptor,
        multi: true,
    },
];
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    return SharedModule;
}());
export { SharedModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34, ɵ35, ɵ36, ɵ37, ɵ38, ɵ39, ɵ40, ɵ41, ɵ42, ɵ43, ɵ44, ɵ45, ɵ46, ɵ47, ɵ48, ɵ49, ɵ50, ɵ51, ɵ52, ɵ53, ɵ54, ɵ55, ɵ56, ɵ57, ɵ58, ɵ59 };
