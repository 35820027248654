import { GetDataSource, MethodNotImplementedError, Query, QueryNotSupportedError } from '@mobilejazz/harmony-core';
import { DictionaryQuery } from '@mobilejazz/harmony-core/dist';
import { ApiEndpoint, StatisticResultModel } from 'smart-statistics-common';
import {
    AverageOccupancyStatisticsQuery,
    AverageSpeedStatisticsQuery,
    LanguageChoiceStatisticsQuery,
    NotWorkingSeatsStatisticsQuery,
} from '../queries/statistics.query';
import { ApiRequestService } from '../services/api-request.service';

export class StatisticResultNetworkDataSource implements GetDataSource<StatisticResultModel> {

    constructor(protected requestService: ApiRequestService) {}

    get(query: Query): Promise<StatisticResultModel>;
    get<K>(id: K): Promise<StatisticResultModel>;
    get<K>(queryOrId: Query | K): Promise<StatisticResultModel> {
        throw new MethodNotImplementedError();
    }

    getAll(query: Query): Promise<StatisticResultModel[]>;
    getAll<K>(ids: K[]): Promise<StatisticResultModel[]>;
    getAll<K>(queryOrIds: Query | K): Promise<StatisticResultModel[]> {

        if (queryOrIds instanceof DictionaryQuery) {

            // TODO : Fix queryType hack once ES5/ES6 transpile is solved in Harmony
            // See:
            // https://stackoverflow.com/questions/51860043/javascript-es6-typeerror-class-constructor-client-cannot-be-invoked-without-ne
            if (queryOrIds.dictionary.queryType === 'AverageOccupancyStatisticsQuery') {
                delete queryOrIds.dictionary.queryType;
                return this.requestService.builder<StatisticResultModel[]>(ApiEndpoint.StatisticAvgOccupancy)
                    .setQueryParameters(queryOrIds.dictionary).setResponseConstructor(StatisticResultModel)
                    .get().toPromise();
            } else if (queryOrIds.dictionary.queryType === 'AverageSpeedStatisticsQuery') {
                delete queryOrIds.dictionary.queryType;
                return this.requestService.builder<StatisticResultModel[]>(ApiEndpoint.StatisticAvgSpeed)
                    .setQueryParameters(queryOrIds.dictionary).setResponseConstructor(StatisticResultModel)
                    .get().toPromise();
            } else if (queryOrIds.dictionary.queryType === 'LanguageChoiceStatisticsQuery') {
                delete queryOrIds.dictionary.queryType;
                return this.requestService.builder<StatisticResultModel[]>(ApiEndpoint.StatisticLanguage)
                    .setQueryParameters(queryOrIds.dictionary).setResponseConstructor(StatisticResultModel)
                    .get().toPromise();

            } else if (queryOrIds.dictionary.queryType === 'NotWorkingSeatsStatisticsQuery') {
                delete queryOrIds.dictionary.queryType;
                return this.requestService.builder<StatisticResultModel[]>(ApiEndpoint.StatisticNotWorkingSeats)
                    .setQueryParameters(queryOrIds.dictionary).setResponseConstructor(StatisticResultModel)
                    .get().toPromise();
            } else {
                throw new QueryNotSupportedError();
            }
        } else {
            throw new QueryNotSupportedError();
        }
    }

}
