import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
    DeleteAllInteractor,
    DeleteInteractor,
    DeleteRepository,
    GetAllInteractor,
    GetInteractor,
    GetRepository,
    JsonDeserializerMapper,
    JsonSerializerMapper,
    LocalStorageDataSource,
    PutAllInteractor,
    PutInteractor,
    PutRepository,
    SingleDataSourceRepository,
} from '@mobilejazz/harmony-core';
import { SingleGetDataSourceRepository } from '@mobilejazz/harmony-core/dist';
import { TranslateModule } from '@ngx-translate/core';
import {
    ChannelModel,
    ClientModel, HardwareIdentifierModel,
    LanguageModel,
    OperationModel,
    RouteModel,
    RouteStopModel,
    StatisticResultModel,
    TokenModel,
    UserModel,
    VehicleModel,
} from 'smart-statistics-common';
import { FuseSharedModule } from '../../@fuse/shared.module';
import { environment } from '../../environments/environment';
import { ChannelNetworkDataSource } from "./data-sources/channel-network.data-source";
import { ClientNetworkDataSource } from './data-sources/client-network.data-source';
import { HardwareIdentifierNetworkDataSource } from './data-sources/hardware-identifier-network.data-source';
import { LanguageNetworkDataSource } from "./data-sources/language-network.data-source";
import { OperationNetworkDataSource } from "./data-sources/operation-network.data-source";
import { RouteNetworkDataSource } from "./data-sources/route-network.data-source";
import { RouteStopNetworkDataSource } from "./data-sources/route-stop-network.data-source";
import { StatisticResultNetworkDataSource } from './data-sources/statistic-result-network.data-source';
import { TokenNetworkDataSource } from './data-sources/token-network.data-source';
import { UserNetworkDataSource } from './data-sources/user-network.data-source';
import { VehicleNetworkDataSource } from "./data-sources/vehicle-network.data-source";
import { LoginInteractor } from './interactors/login.interactor';
import { JwtTokenInterceptor } from './interceptors/jwt-token.interceptor';
import { MaterialLibraryModule } from './material/material-library.module';
import { TokenRepository } from './repository/token.repository';
import { AdminService } from "./services/admin.service";
import { ApiRequestService } from './services/api-request.service';
import { AuthService } from './services/auth.service';
import { ChannelService } from "./services/channel.service";
import { ClientService } from "./services/client.service";
import { HardwareIdentifierService } from './services/hardware-identifier.service';
import { LanguageService } from "./services/language.service";
import { OperationService } from "./services/operation.service";
import { RouteStopService } from "./services/route-stop.service";
import { RouteService } from "./services/route.service";
import { StatisticsService } from "./services/statistics.service";
import { UserService } from './services/user.service';
import { VehicleService } from "./services/vehicle.service";

const SHARED_MODULES = [
    FuseSharedModule,
    MaterialLibraryModule,
];

const SHARED_COMPONENTS = [];

const SHARED_ENTRY_COMPONENTS = [];

const SHARED_PROVIDERS = [
    // CONSTANTS
    {
        provide: 'API_BASE_URL',
        useValue: environment.api.baseUrl,
    },
    // SERVICES
    StatisticsService,
    ApiRequestService,
    AdminService,
    AuthService,
    ChannelService,
    ClientService,
    HardwareIdentifierService,
    LanguageService,
    OperationService,
    RouteService,
    RouteStopService,
    UserService,
    VehicleService,
    // INTERACTORS

    // For UserModel
    {
        provide: 'GetInteractor<UserModel>',
        useFactory: (repository: GetRepository<UserModel>) => {
          return new GetInteractor<UserModel>(repository);
        },
        deps: ['SingleDataSourceRepository<UserModel>'],
    },
    {
        provide: 'GetAllInteractor<UserModel>',
        useFactory: (repository: GetRepository<UserModel>) => {
          return new GetAllInteractor<UserModel>(repository);
        },
        deps: ['SingleDataSourceRepository<UserModel>'],
    },
    {
        provide: 'PutInteractor<UserModel>',
        useFactory: (repository: PutRepository<UserModel>) => {
          return new PutInteractor<UserModel>(repository);
        },
        deps: ['SingleDataSourceRepository<UserModel>'],
    },
    {
        provide: 'DeleteInteractor<UserModel>',
        useFactory: (repository: DeleteRepository) => {
            return new DeleteInteractor(repository);
        },
        deps: ['SingleDataSourceRepository<UserModel>'],
    },

    // For LanguageModel
    {
        provide: 'GetAllInteractor<LanguageModel>',
        useFactory: (repository: GetRepository<LanguageModel>) => {
            return new GetAllInteractor<LanguageModel>(repository);
        },
        deps: ['SingleDataSourceRepository<LanguageModel>'],
    },

    // For ChannelModel
    {
        provide: 'GetInteractor<ChannelModel>',
        useFactory: (repository: GetRepository<ChannelModel>) => {
            return new GetInteractor<ChannelModel>(repository);
        },
        deps: ['SingleDataSourceRepository<ChannelModel>'],
    },
    {
        provide: 'GetAllInteractor<ChannelModel>',
        useFactory: (repository: GetRepository<ChannelModel>) => {
            return new GetAllInteractor<ChannelModel>(repository);
        },
        deps: ['SingleDataSourceRepository<ChannelModel>'],
    },
    {
        provide: 'PutInteractor<ChannelModel>',
        useFactory: (repository: PutRepository<ChannelModel>) => {
            return new PutInteractor<ChannelModel>(repository);
        },
        deps: ['SingleDataSourceRepository<ChannelModel>'],
    },
    {
        provide: 'DeleteInteractor<ChannelModel>',
        useFactory: (repository: DeleteRepository) => {
            return new DeleteInteractor(repository);
        },
        deps: ['SingleDataSourceRepository<ChannelModel>'],
    },

    // For ClientModel
    {
        provide: 'GetInteractor<ClientModel>',
        useFactory: (repository: GetRepository<ClientModel>) => {
          return new GetInteractor<ClientModel>(repository);
        },
        deps: ['SingleDataSourceRepository<ClientModel>'],
    },
    {
        provide: 'GetAllInteractor<ClientModel>',
        useFactory: (repository: GetRepository<ClientModel>) => {
          return new GetAllInteractor<ClientModel>(repository);
        },
        deps: ['SingleDataSourceRepository<ClientModel>'],
    },
    {
        provide: 'PutInteractor<ClientModel>',
        useFactory: (repository: PutRepository<ClientModel>) => {
          return new PutInteractor<ClientModel>(repository);
        },
        deps: ['SingleDataSourceRepository<ClientModel>'],
    },
    {
        provide: 'DeleteInteractor<ClientModel>',
        useFactory: (repository: DeleteRepository) => {
            return new DeleteInteractor(repository);
        },
        deps: ['SingleDataSourceRepository<ClientModel>'],
    },

    // For HardwareIdentifierModel
    {
        provide: 'GetInteractor<HardwareIdentifierModel>',
        useFactory: (repository: GetRepository<HardwareIdentifierModel>) => {
            return new GetInteractor<HardwareIdentifierModel>(repository);
        },
        deps: ['SingleDataSourceRepository<HardwareIdentifierModel>'],
    },
    {
        provide: 'GetAllInteractor<HardwareIdentifierModel>',
        useFactory: (repository: GetRepository<HardwareIdentifierModel>) => {
            return new GetAllInteractor<HardwareIdentifierModel>(repository);
        },
        deps: ['SingleDataSourceRepository<HardwareIdentifierModel>'],
    },
    {
        provide: 'PutInteractor<HardwareIdentifierModel>',
        useFactory: (repository: PutRepository<HardwareIdentifierModel>) => {
            return new PutInteractor<HardwareIdentifierModel>(repository);
        },
        deps: ['SingleDataSourceRepository<HardwareIdentifierModel>'],
    },
    {
        provide: 'DeleteInteractor<HardwareIdentifierModel>',
        useFactory: (repository: DeleteRepository) => {
            return new DeleteInteractor(repository);
        },
        deps: ['SingleDataSourceRepository<HardwareIdentifierModel>'],
    },

    // For OperationModel
    {
        provide: 'GetInteractor<OperationModel>',
        useFactory: (repository: GetRepository<OperationModel>) => {
            return new GetInteractor<OperationModel>(repository);
        },
        deps: ['SingleDataSourceRepository<OperationModel>'],
    },
    {
        provide: 'GetAllInteractor<OperationModel>',
        useFactory: (repository: GetRepository<OperationModel>) => {
            return new GetAllInteractor<OperationModel>(repository);
        },
        deps: ['SingleDataSourceRepository<OperationModel>'],
    },
    {
        provide: 'PutInteractor<OperationModel>',
        useFactory: (repository: PutRepository<OperationModel>) => {
            return new PutInteractor<OperationModel>(repository);
        },
        deps: ['SingleDataSourceRepository<OperationModel>'],
    },
    {
        provide: 'DeleteInteractor<OperationModel>',
        useFactory: (repository: DeleteRepository) => {
            return new DeleteInteractor(repository);
        },
        deps: ['SingleDataSourceRepository<OperationModel>'],
    },

    // For RouteModel
    {
        provide: 'GetInteractor<RouteModel>',
        useFactory: (repository: GetRepository<RouteModel>) => {
            return new GetInteractor<RouteModel>(repository);
        },
        deps: ['SingleDataSourceRepository<RouteModel>'],
    },
    {
        provide: 'GetAllInteractor<RouteModel>',
        useFactory: (repository: GetRepository<RouteModel>) => {
            return new GetAllInteractor<RouteModel>(repository);
        },
        deps: ['SingleDataSourceRepository<RouteModel>'],
    },
    {
        provide: 'PutInteractor<RouteModel>',
        useFactory: (repository: PutRepository<RouteModel>) => {
            return new PutInteractor<RouteModel>(repository);
        },
        deps: ['SingleDataSourceRepository<RouteModel>'],
    },
    {
        provide: 'DeleteInteractor<RouteModel>',
        useFactory: (repository: DeleteRepository) => {
            return new DeleteInteractor(repository);
        },
        deps: ['SingleDataSourceRepository<RouteModel>'],
    },

    // For RouteStopModel
    {
        provide: 'PutAllInteractor<RouteStopModel>',
        useFactory: (repository: PutRepository<RouteStopModel>) => {
            return new PutAllInteractor<RouteStopModel>(repository);
        },
        deps: ['SingleDataSourceRepository<RouteStopModel>'],
    },
    {
        provide: 'DeleteAllInteractor<RouteStopModel>',
        useFactory: (repository: DeleteRepository) => {
            return new DeleteAllInteractor(repository);
        },
        deps: ['SingleDataSourceRepository<RouteStopModel>'],
    },

    // For StatisticResultModel
    {
        provide: 'GetAllInteractor<StatisticResultModel>',
        useFactory: (repository: GetRepository<StatisticResultModel>) => {
            return new GetAllInteractor<StatisticResultModel>(repository);
        },
        deps: ['SingleGetDataSourceRepository<StatisticResultModel>'],
    },
    // For TokenModel
    {
        provide: 'GetInteractor<TokenModel>',
        useFactory: (repository: GetRepository<TokenModel>) => {
            return new GetInteractor<TokenModel>(repository);
        },
        deps: ['TokenRepository'],
    },
    {
        provide: 'PutInteractor<TokenModel>',
        useFactory: (repository: PutRepository<TokenModel>) => {
            return new PutInteractor<TokenModel>(repository);
        },
        deps: ['TokenRepository'],
    },
    {
        provide: 'DeleteInteractor<TokenModel>',
        useFactory: (repository: DeleteRepository) => {
          return new DeleteInteractor(repository);
        },
        deps: ['TokenRepository'],
    },
    {
        provide: 'LoginInteractor',
        useFactory: (
            putInteractor: PutInteractor<TokenModel>,
        ) => {
            return new LoginInteractor(putInteractor);
        },
        deps: ['PutInteractor<TokenModel>'],
    },

    // For VehicleModel
    {
        provide: 'GetInteractor<VehicleModel>',
        useFactory: (repository: GetRepository<VehicleModel>) => {
            return new GetInteractor<VehicleModel>(repository);
        },
        deps: ['SingleDataSourceRepository<VehicleModel>'],
    },
    {
        provide: 'GetAllInteractor<VehicleModel>',
        useFactory: (repository: GetRepository<VehicleModel>) => {
            return new GetAllInteractor<VehicleModel>(repository);
        },
        deps: ['SingleDataSourceRepository<VehicleModel>'],
    },
    {
        provide: 'PutInteractor<VehicleModel>',
        useFactory: (repository: PutRepository<VehicleModel>) => {
            return new PutInteractor<VehicleModel>(repository);
        },
        deps: ['SingleDataSourceRepository<VehicleModel>'],
    },
    {
        provide: 'DeleteInteractor<VehicleModel>',
        useFactory: (repository: DeleteRepository) => {
            return new DeleteInteractor(repository);
        },
        deps: ['SingleDataSourceRepository<VehicleModel>'],
    },

    // REPOSITORIES
    {
        provide: 'SingleDataSourceRepository<ChannelModel>',
        useFactory: (dataSource: ChannelNetworkDataSource) => {
            return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
        },
        deps: ['ChannelNetworkDataSource'],
    },
    {
        provide: 'SingleDataSourceRepository<ClientModel>',
        useFactory: (dataSource: ClientNetworkDataSource) => {
            return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
        },
        deps: ['ClientNetworkDataSource'],
    },
    {
        provide: 'SingleDataSourceRepository<HardwareIdentifierModel>',
        useFactory: (dataSource: HardwareIdentifierNetworkDataSource) => {
            return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
        },
        deps: ['HardwareIdentifierNetworkDataSource'],
    },
    {
        provide: 'SingleDataSourceRepository<LanguageModel>',
        useFactory: (dataSource: LanguageNetworkDataSource) => {
            return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
        },
        deps: ['LanguageNetworkDataSource'],
    },
    {
        provide: 'SingleDataSourceRepository<OperationModel>',
        useFactory: (dataSource: OperationNetworkDataSource) => {
            return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
        },
        deps: ['OperationNetworkDataSource'],
    },
    {
        provide: 'SingleDataSourceRepository<RouteModel>',
        useFactory: (dataSource: RouteNetworkDataSource) => {
            return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
        },
        deps: ['RouteNetworkDataSource'],
    },
    {
        provide: 'SingleDataSourceRepository<RouteStopModel>',
        useFactory: (dataSource: RouteStopNetworkDataSource) => {
            return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
        },
        deps: ['RouteStopNetworkDataSource'],
    },
    {
        provide: 'SingleDataSourceRepository<UserModel>',
        useFactory: (dataSource: UserNetworkDataSource) => {
            return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
        },
        deps: ['UserNetworkDataSource'],
    },
    {
        provide: 'SingleGetDataSourceRepository<StatisticResultModel>',
        useFactory: (dataSource: StatisticResultNetworkDataSource) => {
            return new SingleGetDataSourceRepository(dataSource);
        },
        deps: ['StatisticResultNetworkDataSource'],
    },
    {
        provide: 'SingleDataSourceRepository<VehicleModel>',
        useFactory: (dataSource: VehicleNetworkDataSource) => {
            return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
        },
        deps: ['VehicleNetworkDataSource'],
    },
    {
        provide: 'TokenRepository',
        useFactory: (
            tokenNetworkDataSource: TokenNetworkDataSource,
            localStorageDataSource: LocalStorageDataSource,
        ) => {
            const toOutMapper = new JsonDeserializerMapper<string, TokenModel>(TokenModel);
            const toInMapper = new JsonSerializerMapper<TokenModel>();

            return new TokenRepository(
                tokenNetworkDataSource,
                tokenNetworkDataSource,
                tokenNetworkDataSource,
                localStorageDataSource,
                toOutMapper,
                toInMapper,
            );
        },
        deps: ['TokenNetworkDataSource', LocalStorageDataSource],
    },
    {
        provide: 'SingleDataSourceRepository<TokenModel>',
        useFactory: (dataSource: TokenNetworkDataSource) => {
            return new SingleDataSourceRepository(dataSource, dataSource, dataSource);
        },
        deps: ['TokenNetworkDataSource'],
    },
    // DATA SOURCES
    {
        provide: 'ChannelNetworkDataSource',
        useFactory: (requestService: ApiRequestService) => {
            return new ChannelNetworkDataSource(requestService);
        },
        deps: [ApiRequestService],
    },
    {
        provide: 'ClientNetworkDataSource',
        useFactory: (requestService: ApiRequestService) => {
            return new ClientNetworkDataSource(requestService);
        },
        deps: [ApiRequestService],
    },
    {
        provide: 'HardwareIdentifierNetworkDataSource',
        useFactory: (requestService: ApiRequestService) => {
            return new HardwareIdentifierNetworkDataSource(requestService);
        },
        deps: [ApiRequestService],
    },
    {
        provide: 'LanguageNetworkDataSource',
        useFactory: (requestService: ApiRequestService) => {
            return new LanguageNetworkDataSource(requestService);
        },
        deps: [ApiRequestService],
    },
    {
        provide: 'OperationNetworkDataSource',
        useFactory: (requestService: ApiRequestService) => {
            return new OperationNetworkDataSource(requestService);
        },
        deps: [ApiRequestService],
    },
    {
        provide: 'RouteNetworkDataSource',
        useFactory: (requestService: ApiRequestService) => {
            return new RouteNetworkDataSource(requestService);
        },
        deps: [ApiRequestService],
    },
    {
        provide: 'RouteStopNetworkDataSource',
        useFactory: (requestService: ApiRequestService) => {
            return new RouteStopNetworkDataSource(requestService);
        },
        deps: [ApiRequestService],
    },
    {
        provide: 'UserNetworkDataSource',
        useFactory: (requestService: ApiRequestService) => {
            return new UserNetworkDataSource(requestService);
        },
        deps: [ApiRequestService],
    },
    {
        provide: 'StatisticResultNetworkDataSource',
        useFactory: (requestService: ApiRequestService) => {
            return new StatisticResultNetworkDataSource(requestService);
        },
        deps: [ApiRequestService],
    },
    {
        provide: 'TokenNetworkDataSource',
        useFactory: (requestService: ApiRequestService) => {
            return new TokenNetworkDataSource(requestService);
        },
        deps: [ApiRequestService],
    },
    {
        provide: 'VehicleNetworkDataSource',
        useFactory: (requestService: ApiRequestService) => {
            return new VehicleNetworkDataSource(requestService);
        },
        deps: [ApiRequestService],
    },
    LocalStorageDataSource,
    // INTERCEPTORS
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtTokenInterceptor,
        multi: true,
    },

];

@NgModule({
    imports: [
        SHARED_MODULES,
    ],
    declarations: [
        SHARED_COMPONENTS,
        SHARED_ENTRY_COMPONENTS,
    ],
    entryComponents: [
        SHARED_ENTRY_COMPONENTS,
    ],
    providers: [
        SHARED_PROVIDERS,
    ],
    exports: [
        SHARED_COMPONENTS,
        SHARED_ENTRY_COMPONENTS,
        SHARED_MODULES,
        TranslateModule,
    ],
})
export class SharedModule {
}
