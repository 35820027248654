import { Inject, Injectable } from '@angular/core';
import { LoginInteractor } from '../interactors/login.interactor';
import { DeleteInteractor, StorageOperation } from "@mobilejazz/harmony-core";

@Injectable()
export class AuthService {

    constructor(
        @Inject('LoginInteractor')
        protected loginInteractor: LoginInteractor,
        @Inject('DeleteInteractor<TokenModel>')
        private deleteInteractor: DeleteInteractor,
    ) {}

    public login(email: string, password: string): Promise<void> {
        return this.loginInteractor.execute( email, password);
    }

    public logout() {
        return this.deleteInteractor.execute('token', new StorageOperation());
    }
}
