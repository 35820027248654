import { Inject, Injectable } from '@angular/core';
import { DeleteInteractor, GetAllInteractor, GetInteractor, IdQuery, PutInteractor } from '@mobilejazz/harmony-core';
import { HardwareIdentifierModel } from 'smart-statistics-common';

@Injectable()
export class HardwareIdentifierService {

    constructor(
        @Inject('GetInteractor<HardwareIdentifierModel>')
        protected getHardwareIdentifierInteractor: GetInteractor<HardwareIdentifierModel>,
        @Inject('GetAllInteractor<HardwareIdentifierModel>')
        protected getAllHardwareIdentifierInteractor: GetAllInteractor<HardwareIdentifierModel>,
        @Inject('PutInteractor<HardwareIdentifierModel>')
        protected putHardwareIdentifierInteractor: PutInteractor<HardwareIdentifierModel>,
        @Inject('DeleteInteractor<HardwareIdentifierModel>')
        protected deleteHardwareIdentifierInteractor: DeleteInteractor,
    ) {}

    public getAll(): Promise<HardwareIdentifierModel[]> {
        return this.getAllHardwareIdentifierInteractor.execute();
    }

    public put(hardwareIdentifier: HardwareIdentifierModel): Promise<HardwareIdentifierModel> {
        return this.putHardwareIdentifierInteractor.execute(hardwareIdentifier);
    }

    public delete(hardwareIdentifier: HardwareIdentifierModel): Promise<void> {
        return this.deleteHardwareIdentifierInteractor.execute(new IdQuery(hardwareIdentifier.id));
    }

}
