import {
    DeleteDataSource,
    GetDataSource,
    MethodNotImplementedError,
    PutDataSource,
    Query,
} from '@mobilejazz/harmony-core';
import {ApiEndpoint, OperationModel, VehicleModel} from 'smart-statistics-common';
import { ApiRequestService } from '../services/api-request.service';

export class VehicleNetworkDataSource implements GetDataSource<VehicleModel>, PutDataSource<VehicleModel>, DeleteDataSource {

    constructor(protected requestService: ApiRequestService) {}

    get(query: Query): Promise<VehicleModel>;
    get<K>(id: K): Promise<VehicleModel>;
    get(query): Promise<VehicleModel> {
        throw new MethodNotImplementedError();
    }

    getAll(query: Query): Promise<VehicleModel[]>;
    getAll<K>(ids: K[]): Promise<VehicleModel[]>;
    getAll(query): Promise<VehicleModel[]> {
        return this.requestService
            .builder<VehicleModel[]>(ApiEndpoint.Vehicle)
            .setUrlParameters({operationId: query.value.operationId})
            .setResponseConstructor(VehicleModel)
            .get().toPromise();
    }

    delete(query: Query): Promise<void>;
    delete<K>(id: K): Promise<void>;
    delete(query): Promise<void> {
        return this.requestService
            .builder<VehicleModel>(ApiEndpoint.VehicleOne)
            .setUrlParameters({operationId: query.value.operationId, vehicleId: query.value.vehicleId})
            .delete().toPromise();
    }

    deleteAll(query: Query): Promise<void>;
    deleteAll<K>(ids: K[]): Promise<void>;
    deleteAll(query): Promise<void> {
        throw new MethodNotImplementedError();
    }

    put(value: VehicleModel, query: Query): Promise<VehicleModel>;
    put<K>(value: VehicleModel, id: K): Promise<VehicleModel>;
    put(value: VehicleModel, query): Promise<VehicleModel> {
        if (value.id) {
            return this.requestService
                .builder<VehicleModel>(ApiEndpoint.VehicleOne)
                .setUrlParameters({operationId: query.value.operationId, vehicleId: value.id})
                .setResponseConstructor(VehicleModel)
                .setBody(value)
                .put().toPromise();
        }
        return this.requestService
            .builder<VehicleModel>(ApiEndpoint.Vehicle)
            .setUrlParameters({operationId: query.value.operationId})
            .setResponseConstructor(VehicleModel)
            .setBody(value)
            .post().toPromise();
    }

    putAll(values: VehicleModel[], query: Query): Promise<VehicleModel[]>;
    putAll<K>(values: VehicleModel[], ids: K[]): Promise<VehicleModel[]>;
    putAll(values: VehicleModel[], query): Promise<VehicleModel[]> {
        throw new MethodNotImplementedError();
    }

}
