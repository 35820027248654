import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ClientModel, OperationModel, UserModel } from 'smart-statistics-common';

@Injectable()
export class ContextService {
    public authenticatedUser: BehaviorSubject<UserModel> = new BehaviorSubject(null);
    public currentClient: BehaviorSubject<ClientModel> = new BehaviorSubject(null);
    public currentOperation: BehaviorSubject<OperationModel> = new BehaviorSubject(null);

    constructor(
        @Inject(Router)
        private router: Router,
    ) {
        this.currentClient.subscribe((client: ClientModel) => {
            if (client && client.operations.length) {
                if (!this.currentOperation.value) {
                    this.currentOperation.next(client.operations[0]);
                }
            } else {
                this.currentOperation.next(null);
            }
        });
    }

    public logout() {
        this.authenticatedUser.next(null);
        this.router.navigate(['/login']);
    }
}
