import {
    DeleteDataSource,
    GetDataSource,
    MethodNotImplementedError,
    PutDataSource,
    Query,
} from '@mobilejazz/harmony-core';
import { ApiEndpoint, RouteModel } from 'smart-statistics-common';
import { ApiRequestService } from '../services/api-request.service';
import {IdQuery} from "@mobilejazz/harmony-core/dist";

export class RouteNetworkDataSource implements GetDataSource<RouteModel>, PutDataSource<RouteModel>, DeleteDataSource {

    constructor(protected requestService: ApiRequestService) {}

    get(query: Query): Promise<RouteModel>;
    get<K>(id: K): Promise<RouteModel>;
    get(query): Promise<RouteModel> {
        return this.requestService
            .builder<RouteModel>(ApiEndpoint.RouteOne)
            .setUrlParameters({operationId: query.value.operationId, routeId: query.value.routeId})
            .setResponseConstructor(RouteModel)
            .get().toPromise();
    }

    getAll(query: Query): Promise<RouteModel[]>;
    getAll<K>(ids: K[]): Promise<RouteModel[]>;
    getAll(query): Promise<RouteModel[]> {
        return this.requestService
            .builder<RouteModel[]>(ApiEndpoint.Route)
            .setUrlParameters({operationId: query.value.operationId})
            .setResponseConstructor(RouteModel)
            .get().toPromise();
    }

    delete(query: Query): Promise<void>;
    delete<K>(id: K): Promise<void>;
    delete(query): Promise<void> {
        return this.requestService
            .builder<RouteModel>(ApiEndpoint.RouteOne)
            .setUrlParameters({operationId: query.value.operationId, routeId: query.value.routeId})
            .delete().toPromise();
    }

    deleteAll(query: Query): Promise<void>;
    deleteAll<K>(ids: K[]): Promise<void>;
    deleteAll(query): Promise<void> {
        throw new MethodNotImplementedError();
    }

    put(value: RouteModel, query: Query): Promise<RouteModel>;
    put<K>(value: RouteModel, id: K): Promise<RouteModel>;
    put<K>(value: RouteModel, queryOrId: Query | K): Promise<RouteModel> {
        if (queryOrId instanceof IdQuery) {
            // TODO : In the future we should refactor to reference DTOs and not Models
            if (value.id) {
                return this.requestService
                    .builder<RouteModel>(ApiEndpoint.RouteOne)
                    .setUrlParameters({operationId: queryOrId.id, routeId: value.id})
                    .setResponseConstructor(RouteModel)
                    .setBody(value)
                    .put().toPromise();
            }
            return this.requestService
                .builder<RouteModel>(ApiEndpoint.Route)
                .setUrlParameters({operationId: queryOrId.id})
                .setResponseConstructor(RouteModel)
                .setBody(value)
                .post().toPromise();
        }
    }

    putAll(values: RouteModel[], query: Query): Promise<RouteModel[]>;
    putAll<K>(values: RouteModel[], ids: K[]): Promise<RouteModel[]>;
    putAll(values: RouteModel[], query): Promise<RouteModel[]> {
        throw new MethodNotImplementedError();
    }

}
