import {
    DeleteDataSource,
    GetDataSource,
    MethodNotImplementedError,
    PutDataSource,
    Query,
    QueryNotSupportedError,
} from '@mobilejazz/harmony-core';
import { IdQuery } from '@mobilejazz/harmony-core/dist';
import { ApiEndpoint, HardwareIdentifierModel, OperationModel } from 'smart-statistics-common';
import { ApiRequestService } from '../services/api-request.service';

export class HardwareIdentifierNetworkDataSource implements GetDataSource<HardwareIdentifierModel>,
    PutDataSource<HardwareIdentifierModel>, DeleteDataSource {

    constructor(protected requestService: ApiRequestService) {}

    get(query: Query): Promise<HardwareIdentifierModel>;
    get<K>(id: K): Promise<HardwareIdentifierModel>;
    get<K>(queryOrId: Query | K): Promise<HardwareIdentifierModel> {

        let request = this.requestService
            .builder<HardwareIdentifierModel>(ApiEndpoint.HardwareIdentifierOne)
            .setResponseConstructor(HardwareIdentifierModel);

        if (queryOrId instanceof Query) {
            throw new QueryNotSupportedError();
        } else if (typeof queryOrId === 'string' || typeof queryOrId === 'number' ) {
            request = request
            .setUrlParameters({hardwareIdentifierId: queryOrId});
        } else {
            throw new QueryNotSupportedError();
        }

        return request.get().toPromise();
    }

    getAll(query: Query): Promise<HardwareIdentifierModel[]>;
    getAll<K>(ids: K[]): Promise<HardwareIdentifierModel[]>;
    getAll(query): Promise<HardwareIdentifierModel[]> {
        return this.requestService
            .builder<HardwareIdentifierModel[]>(ApiEndpoint.HardwareIdentifier)
            .setResponseConstructor(HardwareIdentifierModel)
            .get().toPromise();
    }

    delete(query: Query): Promise<void>;
    delete<K>(id: K): Promise<void>;
    delete<K>(queryOrId: Query | K): Promise<void> {
        if (queryOrId instanceof IdQuery) {
            this.requestService
            .builder<OperationModel>(ApiEndpoint.HardwareIdentifierOne)
            .setUrlParameters({hardwareIdentifierId: queryOrId.id})
            .delete().toPromise();
        } else {
            throw new QueryNotSupportedError();
        }
        return;
    }

    deleteAll(query: Query): Promise<void>;
    deleteAll<K>(ids: K[]): Promise<void>;
    deleteAll(query): Promise<void> {
        throw new MethodNotImplementedError();
    }

    put(value: HardwareIdentifierModel, query: Query): Promise<HardwareIdentifierModel>;
    put<K>(value: HardwareIdentifierModel, id: K): Promise<HardwareIdentifierModel>;
    put(value: HardwareIdentifierModel, query): Promise<HardwareIdentifierModel> {

        return this.requestService
            .builder<HardwareIdentifierModel>(ApiEndpoint.HardwareIdentifier)
            .setResponseConstructor(HardwareIdentifierModel)
            .setBody(value).post().toPromise();
    }

    putAll(values: HardwareIdentifierModel[], query: Query): Promise<HardwareIdentifierModel[]>;
    putAll<K>(values: HardwareIdentifierModel[], ids: K[]): Promise<HardwareIdentifierModel[]>;
    putAll(values: HardwareIdentifierModel[], query): Promise<HardwareIdentifierModel[]> {
        throw new MethodNotImplementedError();
    }

}
