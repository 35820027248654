import { Inject, Injectable } from '@angular/core';
import { GetAllInteractor, GetInteractor, PutInteractor, VoidQuery } from '@mobilejazz/harmony-core';
import { DeleteInteractor, IdQuery } from '@mobilejazz/harmony-core/dist';
import { ClientModel } from 'smart-statistics-common';

@Injectable()
export class ClientService implements CRUDService<ClientModel> {

    constructor(
        @Inject('GetInteractor<ClientModel>')
        protected getClientInteractor: GetInteractor<ClientModel>,
        @Inject('GetAllInteractor<ClientModel>')
        protected getAllClientInteractor: GetAllInteractor<ClientModel>,
        @Inject('PutInteractor<ClientModel>')
        protected putClientInteractor: PutInteractor<ClientModel>,
        @Inject('DeleteInteractor<ClientModel>')
        protected deleteClientInteractor: DeleteInteractor,
    ) {}

    public get(id: number | string): Promise<ClientModel> {
        return this.getClientInteractor.execute(id);
    }

    public getAll(): Promise<ClientModel[]> {
        return this.getAllClientInteractor.execute(new VoidQuery());
    }

    public put(client: ClientModel): Promise<ClientModel> {
        delete client.operations;
        return this.putClientInteractor.execute(client);
    }

    public delete(client: ClientModel): Promise<void> {
        return this.deleteClientInteractor.execute(new IdQuery(client.id));
    }

}
