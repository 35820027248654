import * as tslib_1 from "tslib";
import { DefaultOperation, NetworkOperation, OperationNotSupportedError, StorageOperation, VoidQuery, } from '@mobilejazz/harmony-core';
var TokenRepository = /** @class */ (function () {
    function TokenRepository(getNetworkDataSource, putNetworkDataSource, deleteNetworkDataSource, localStorageDataSource, toOutMapper, toInMapper) {
        this.getNetworkDataSource = getNetworkDataSource;
        this.putNetworkDataSource = putNetworkDataSource;
        this.deleteNetworkDataSource = deleteNetworkDataSource;
        this.localStorageDataSource = localStorageDataSource;
        this.toOutMapper = toOutMapper;
        this.toInMapper = toInMapper;
    }
    TokenRepository.prototype.get = function (queryOrId, operation) {
        if (operation === void 0) { operation = new DefaultOperation(); }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(operation instanceof NetworkOperation || operation instanceof DefaultOperation)) return [3 /*break*/, 1];
                        return [2 /*return*/, this.getNetworkDataSource.get(queryOrId)];
                    case 1:
                        if (!(operation instanceof StorageOperation)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.localStorageDataSource.get(queryOrId)];
                    case 2:
                        result = _a.sent();
                        return [2 /*return*/, this.toOutMapper.map(result)];
                    case 3: throw OperationNotSupportedError;
                }
            });
        });
    };
    TokenRepository.prototype.getAll = function (queryOrId, operation) {
        if (operation === void 0) { operation = new DefaultOperation(); }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var results;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(operation instanceof NetworkOperation || operation instanceof DefaultOperation)) return [3 /*break*/, 1];
                        return [2 /*return*/, this.getNetworkDataSource.getAll(queryOrId)];
                    case 1:
                        if (!(operation instanceof StorageOperation)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.localStorageDataSource.getAll(queryOrId)];
                    case 2:
                        results = _a.sent();
                        return [2 /*return*/, results.map(function (r) { return _this.toOutMapper.map(r); })];
                    case 3: throw OperationNotSupportedError;
                }
            });
        });
    };
    TokenRepository.prototype.put = function (value, queryOrId, operation) {
        if (queryOrId === void 0) { queryOrId = new VoidQuery(); }
        if (operation === void 0) { operation = new DefaultOperation(); }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mapped, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(operation instanceof NetworkOperation || operation instanceof DefaultOperation)) return [3 /*break*/, 1];
                        return [2 /*return*/, this.putNetworkDataSource.put(value, queryOrId)];
                    case 1:
                        if (!(operation instanceof StorageOperation)) return [3 /*break*/, 3];
                        mapped = this.toInMapper.map(value);
                        return [4 /*yield*/, this.localStorageDataSource.put(mapped, queryOrId)];
                    case 2:
                        result = _a.sent();
                        return [2 /*return*/, this.toOutMapper.map(result)];
                    case 3: throw OperationNotSupportedError;
                }
            });
        });
    };
    TokenRepository.prototype.putAll = function (values, queryOrIds, operation) {
        if (queryOrIds === void 0) { queryOrIds = new VoidQuery(); }
        if (operation === void 0) { operation = new DefaultOperation(); }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mapped, results;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(operation instanceof NetworkOperation || operation instanceof DefaultOperation)) return [3 /*break*/, 1];
                        return [2 /*return*/, this.putNetworkDataSource.putAll(values, queryOrIds)];
                    case 1:
                        if (!(operation instanceof StorageOperation)) return [3 /*break*/, 3];
                        mapped = this.toInMapper.map(values);
                        return [4 /*yield*/, this.localStorageDataSource.putAll(mapped, queryOrIds)];
                    case 2:
                        results = _a.sent();
                        return [2 /*return*/, results.map(function (r) { return _this.toOutMapper.map(r); })];
                    case 3: throw OperationNotSupportedError;
                }
            });
        });
    };
    TokenRepository.prototype.delete = function (queryOrId, operation) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (operation instanceof NetworkOperation || operation instanceof DefaultOperation) {
                    return [2 /*return*/, this.deleteNetworkDataSource.delete(queryOrId)];
                }
                else if (operation instanceof StorageOperation) {
                    return [2 /*return*/, this.localStorageDataSource.delete(queryOrId)];
                }
                throw OperationNotSupportedError;
            });
        });
    };
    TokenRepository.prototype.deleteAll = function (queryOrIds, operation) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (operation instanceof NetworkOperation || operation instanceof DefaultOperation) {
                    return [2 /*return*/, this.deleteNetworkDataSource.deleteAll(queryOrIds)];
                }
                else if (operation instanceof StorageOperation) {
                    return [2 /*return*/, this.localStorageDataSource.deleteAll(queryOrIds)];
                }
                throw OperationNotSupportedError;
            });
        });
    };
    return TokenRepository;
}());
export { TokenRepository };
