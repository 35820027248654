import {Inject, Injectable} from '@angular/core';
import {DeleteAllInteractor, ObjectQuery, PutAllInteractor, VoidQuery} from '@mobilejazz/harmony-core';
import {RouteStopModel} from 'smart-statistics-common';
import {DefaultOperation} from "@mobilejazz/harmony-core/dist";

@Injectable()
export class RouteStopService {

    constructor(
        @Inject('DeleteAllInteractor<RouteStopModel>')
        protected deleteAllRouteStopInteractor: DeleteAllInteractor,
        @Inject('PutAllInteractor<RouteStopModel>')
        protected putAllRouteStopInteractor: PutAllInteractor<RouteStopModel>,
    ) {
    }

    public putAll(stops: RouteStopModel[], routeId: number, operationId: number): Promise<RouteStopModel[]> {
        return this.putAllRouteStopInteractor.execute(
            stops,
            new ObjectQuery({operationId: operationId, routeId: routeId}),
            // TODO make it optional in harmony
            new DefaultOperation(),
        );
    }

    public deleteAll(stops: number[], routeId: number, operationId: number): Promise<void> {
        return this.deleteAllRouteStopInteractor.execute(
            new ObjectQuery({operationId: operationId, routeId: routeId, stops: stops}),
            // TODO make it optional in harmony
            new DefaultOperation(),
        );
    }

}
