import { KeyQuery, ObjectQuery, PutInteractor, StorageOperation } from '@mobilejazz/harmony-core';
import { TokenModel } from 'smart-statistics-common';

export class LoginInteractor {

    constructor(
        private readonly putInteractor: PutInteractor<TokenModel>,
    ) {}

    async execute(email: string, password: string): Promise<void> {
            let token = await this.putInteractor.execute(null, new ObjectQuery({
                email: email,
                password: password,
            }));
            if (token) {
                this.putInteractor.execute(token, new KeyQuery('token'), new StorageOperation());
            }
    }
}
