import { Inject, Injectable } from '@angular/core';
import {
    DeleteInteractor,
    GetAllInteractor,
    GetInteractor,
    ObjectQuery,
    PutInteractor,
} from '@mobilejazz/harmony-core';
import { VehicleModel } from 'smart-statistics-common';

@Injectable()
export class VehicleService implements CRUDService<VehicleModel> {

    constructor(
        @Inject('DeleteInteractor<VehicleModel>')
        protected deleteVehicleInteractor: DeleteInteractor,
        @Inject('GetInteractor<VehicleModel>')
        protected getVehicleInteractor: GetInteractor<VehicleModel>,
        @Inject('GetAllInteractor<VehicleModel>')
        protected getAllVehicleInteractor: GetAllInteractor<VehicleModel>,
        @Inject('PutInteractor<VehicleModel>')
        protected putVehicleInteractor: PutInteractor<VehicleModel>,
    ) {}

    public getAll(operationId: number): Promise<VehicleModel[]> {
        return this.getAllVehicleInteractor.execute(new ObjectQuery({operationId: operationId}));
    }

    public put(vehicle: VehicleModel, operationId: number): Promise<VehicleModel> {
        return this.putVehicleInteractor.execute(vehicle, new ObjectQuery({operationId: operationId}));
    }

    public delete(vehicle: VehicleModel, operationId: number): Promise<void> {
        return this.deleteVehicleInteractor.execute(new ObjectQuery({operationId: operationId, vehicleId: vehicle.id}));
    }

}
