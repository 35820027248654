import { Inject, Injectable } from '@angular/core';
import { Dictionary, DictionaryQuery, GetAllInteractor, ParameterType } from '@mobilejazz/harmony-core/dist';
import { StatisticRequestModel, StatisticResultModel } from 'smart-statistics-common';
import { AnalyticsData } from "../../private-web/client-view/modules/statistics/pages/dashboard/analytics-data";
import {
    AverageOccupancyStatisticsQuery,
    AverageSpeedStatisticsQuery, LanguageChoiceStatisticsQuery,
    NotWorkingSeatsStatisticsQuery,
} from '../queries/statistics.query';

@Injectable()
export class StatisticsService {

    constructor(
        @Inject('GetAllInteractor<StatisticResultModel>')
        private getAllStatisticsInteractor: GetAllInteractor<StatisticResultModel>,
    ) {}

    // TODO : Remove mocked data
    public getAll(): Promise<any> {
        return new Promise<any>((resolve) => resolve(AnalyticsData.widgets));
    }

    public getAverageOccupancy(request: StatisticRequestModel): Promise<StatisticResultModel[]> {
        let parameters = this.parseParameters(request, 'AverageOccupancyStatisticsQuery');
        return this.getAllStatisticsInteractor.execute(new DictionaryQuery(parameters));
    }

    public getAverageSpeed(request: StatisticRequestModel): Promise<StatisticResultModel[]> {
        let parameters = this.parseParameters(request, 'AverageSpeedStatisticsQuery');
        return this.getAllStatisticsInteractor.execute(new DictionaryQuery(parameters));
    }

    public getLanguageChoice(request: StatisticRequestModel): Promise<StatisticResultModel[]> {
        let parameters = this.parseParameters(request, 'LanguageChoiceStatisticsQuery');
        return this.getAllStatisticsInteractor.execute(new DictionaryQuery(parameters));
    }

    public getNotWorkingSeats(request: StatisticRequestModel): Promise<StatisticResultModel[]> {
        let parameters = this.parseParameters(request, 'NotWorkingSeatsStatisticsQuery');
        return this.getAllStatisticsInteractor.execute(new DictionaryQuery(parameters));
    }

    private parseParameters(request: StatisticRequestModel, queryType: string): Dictionary<ParameterType> {
        let parameters: Dictionary<ParameterType> = {
            startDate: request.startDate,
            endDate: request.endDate,
            queryType: queryType,
        };

        if (request.clientIds && request.clientIds.length) {
            parameters.clientIds = request.clientIds;
        }

        if (request.operationIds && request.operationIds.length) {
            parameters.operationIds = request.operationIds;
        }

        if (request.vehicleIds && request.vehicleIds.length) {
            parameters.vehicleIds = request.vehicleIds;
        }

        if (request.routeIds && request.routeIds.length) {
            parameters.routeIds = request.routeIds;
        }

        if (request.routeStopIds && request.routeStopIds.length) {
            parameters.routeStopIds = request.routeStopIds;
        }

        return parameters;
    }

}
