import {
    DeleteDataSource,
    GetDataSource,
    MethodNotImplementedError,
    ObjectQuery,
    PutDataSource,
    Query,
    QueryNotSupportedError,
} from '@mobilejazz/harmony-core';
import { ApiEndpoint, TokenModel } from 'smart-statistics-common';
import { ApiRequestService } from '../services/api-request.service';

export class TokenNetworkDataSource implements GetDataSource<TokenModel>, PutDataSource<TokenModel>, DeleteDataSource {

    constructor(protected requestService: ApiRequestService) {}

    get(query: Query): Promise<TokenModel>;
    get<K>(id: K): Promise<TokenModel>;
    get<K>(queryOrId: Query | K): Promise<TokenModel> {
        throw new MethodNotImplementedError();
    }

    getAll(query: Query): Promise<TokenModel[]>;
    getAll<K>(ids: K[]): Promise<TokenModel[]>;
    getAll<K>(queryOrIds: Query | K[]): Promise<TokenModel[]> {
        throw new MethodNotImplementedError();
    }

    // TODO: Set proper value type "LoginRequestDTO"
    put(value: TokenModel, query: Query): Promise<TokenModel>;
    put<K>(value: TokenModel, id: K): Promise<TokenModel>;
    put<K>(value: TokenModel, queryOrId: Query | K): Promise<TokenModel> {
        if (queryOrId instanceof ObjectQuery) {
            return this.requestService
                .builder<TokenModel>(ApiEndpoint.PublicAuthLogin)
                .setResponseConstructor(TokenModel)
                .setBody(queryOrId.value)
                .post().toPromise();
        }
        throw QueryNotSupportedError;
    }

    putAll(values: TokenModel[], query: Query): Promise<TokenModel[]>;
    putAll<K>(values: TokenModel[], ids: K[]): Promise<TokenModel[]>;
    putAll<K>(values: TokenModel[], queryOrIds: Query | K[]): Promise<TokenModel[]> {
        throw new MethodNotImplementedError();
    }

    delete(query: Query): Promise<void>;
    delete<K>(id: K): Promise<void>;
    delete<K>(queryOrId: Query | K): Promise<void> {
        throw new MethodNotImplementedError();
    }

    deleteAll(query: Query): Promise<void>;
    deleteAll<K>(ids: K[]): Promise<void>;
    deleteAll<K>(queryOrIds: Query | K[]): Promise<void> {
        throw new MethodNotImplementedError();
    }

}
