import {Inject, Injectable} from '@angular/core';
import {GetAllInteractor, GetInteractor, ObjectQuery, PutInteractor} from '@mobilejazz/harmony-core';
import { DeleteInteractor, IdQuery } from '@mobilejazz/harmony-core/dist';
import {UserModel, UserModelRole} from 'smart-statistics-common';

@Injectable()
export class AdminService {

    constructor(
        @Inject('GetInteractor<UserModel>')
        protected getUserInteractor: GetInteractor<UserModel>,
        @Inject('GetAllInteractor<UserModel>')
        protected getAllUserInteractor: GetAllInteractor<UserModel>,
        @Inject('PutInteractor<UserModel>')
        protected putUserInteractor: PutInteractor<UserModel>,
        @Inject('DeleteInteractor<UserModel>')
        protected deleteUserInteractor: DeleteInteractor,
    ) {}

    public getAll(): Promise<UserModel[]> {
        return this.getAllUserInteractor.execute(new ObjectQuery({role: UserModelRole.Admin}));
    }

    public post(user: UserModel): Promise<UserModel> {
        return this.putUserInteractor.execute(user, new ObjectQuery({}));
    }

    public put(user: UserModel): Promise<UserModel> {
        return this.putUserInteractor.execute(user);
    }

    public delete(user: UserModel): Promise<void> {
        return this.deleteUserInteractor.execute(new IdQuery(user.id));
    }

}
