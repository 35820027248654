import {
    DeleteDataSource,
    GetDataSource,
    MethodNotImplementedError,
    PutDataSource,
    Query,
} from '@mobilejazz/harmony-core';
import { IdQuery, ObjectQuery, QueryNotSupportedError, VoidQuery } from '@mobilejazz/harmony-core/dist';
import { ApiEndpoint, OperationModel, UserModel } from 'smart-statistics-common';
import { ApiRequestService } from '../services/api-request.service';

export class UserNetworkDataSource implements GetDataSource<UserModel>, PutDataSource<UserModel>, DeleteDataSource {

    constructor(protected requestService: ApiRequestService) {}

    get(query: Query): Promise<UserModel>;
    get<K>(id: K): Promise<UserModel>;
    get<K>(queryOrId: Query | K): Promise<UserModel> {
        if (queryOrId instanceof VoidQuery) {
            return this.requestService
                .builder<UserModel>(ApiEndpoint.UserMe)
                .setResponseConstructor(UserModel)
                .get().toPromise();
        } else {
            throw new MethodNotImplementedError();
        }

    }

    getAll(query: Query): Promise<UserModel[]>;
    getAll<K>(ids: K[]): Promise<UserModel[]>;
    getAll<K>(queryOrId: Query | K ): Promise<UserModel[]> {
        let request = this.requestService
            .builder<UserModel[]>(ApiEndpoint.User)
            .setResponseConstructor(UserModel);

        if (queryOrId instanceof ObjectQuery) {
            request = request.setQueryParameters(queryOrId.value);
        }

        return request.get().toPromise();
    }

    delete(query: Query): Promise<void>;
    delete<K>(id: K): Promise<void>;
    delete<K>(queryOrId: Query | K): Promise<void> {
        if (queryOrId instanceof IdQuery) {
            this.requestService
                .builder<OperationModel>(ApiEndpoint.UserOne)
                .setUrlParameters({userId: queryOrId.id})
                .delete().toPromise();
        } else {
            throw new QueryNotSupportedError();
        }
        return;
    }

    deleteAll(query: Query): Promise<void>;
    deleteAll<K>(ids: K[]): Promise<void>;
    deleteAll(query): Promise<void> {
        throw new MethodNotImplementedError();
    }

    put(value: UserModel, query: Query): Promise<UserModel>;
    put<K>(value: UserModel, id: K): Promise<UserModel>;
    put<K>(value: UserModel, queryOrId: Query |K): Promise<UserModel> {
        if (queryOrId instanceof ObjectQuery) {
            const queryParams = queryOrId.value;
            return this.requestService
                .builder<UserModel>(ApiEndpoint.User)
                .setResponseConstructor(UserModel)
                .setQueryParameters(queryParams)
                .setBody(value)
                .post().toPromise();
        } else if (queryOrId instanceof VoidQuery) {
            return this.requestService
                .builder<UserModel>(ApiEndpoint.UserOne)
                .setUrlParameters({userId: value.id})
                .setResponseConstructor(UserModel)
                .setBody({name: value.name})
                .put().toPromise();
        } else {
            throw new QueryNotSupportedError();
        }
    }

    putAll(values: UserModel[], query: Query): Promise<UserModel[]>;
    putAll<K>(values: UserModel[], ids: K[]): Promise<UserModel[]>;
    putAll(values: UserModel[], query): Promise<UserModel[]> {
        throw new MethodNotImplementedError();
    }

}
