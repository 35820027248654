import {
    DeleteDataSource,
    GetDataSource,
    MethodNotImplementedError,
    PutDataSource,
    Query,
} from '@mobilejazz/harmony-core';
import { IdQuery } from '@mobilejazz/harmony-core/dist';
import { ApiEndpoint, ChannelModel } from 'smart-statistics-common';
import { ApiRequestService } from '../services/api-request.service';

export class ChannelNetworkDataSource implements GetDataSource<ChannelModel>, PutDataSource<ChannelModel>, DeleteDataSource {

    constructor(protected requestService: ApiRequestService) {}

    get(query: Query): Promise<ChannelModel>;
    get<K>(id: K): Promise<ChannelModel>;
    get(query): Promise<ChannelModel> {
        throw new MethodNotImplementedError();
    }

    getAll(query: Query): Promise<ChannelModel[]>;
    getAll<K>(ids: K[]): Promise<ChannelModel[]>;
    getAll(query): Promise<ChannelModel[]> {
        return this.requestService
            .builder<ChannelModel[]>(ApiEndpoint.Channel)
            .setResponseConstructor(ChannelModel)
            .setUrlParameters({operationId: query.value.operationId})
            .get().toPromise();
    }

    delete(query: Query): Promise<void>;
    delete<K>(id: K): Promise<void>;
    delete(query): Promise<void> {
        return this.requestService
            .builder<ChannelModel>(ApiEndpoint.ChannelOne)
            .setUrlParameters({operationId: query.value.operationId, channelId: query.value.channelId})
            .delete().toPromise();
    }

    deleteAll(query: Query): Promise<void>;
    deleteAll<K>(ids: K[]): Promise<void>;
    deleteAll(query): Promise<void> {
        throw new MethodNotImplementedError();
    }

    put(value: ChannelModel, query: Query): Promise<ChannelModel>;
    put<K>(value: ChannelModel, id: K): Promise<ChannelModel>;
    put<K>(value: ChannelModel, queryOrId: Query | K): Promise<ChannelModel> {
        if (queryOrId instanceof IdQuery) {
            // TODO : In the future we should refactor to reference DTOs and not Models
            const body = Object.assign({languageId: null}, value);
            body.languageId = body.language.id;
            delete body.language;
            if (value.id) {
                return this.requestService
                    .builder<ChannelModel>(ApiEndpoint.ChannelOne)
                    .setUrlParameters({operationId: queryOrId.id, channelId: value.id})
                    .setResponseConstructor(ChannelModel)
                    .setBody(body)
                    .put().toPromise();
            }
            return this.requestService
                .builder<ChannelModel>(ApiEndpoint.Channel)
                .setUrlParameters({operationId: queryOrId.id})
                .setResponseConstructor(ChannelModel)
                .setBody(body)
                .post().toPromise();
        }
    }

    putAll(values: ChannelModel[], query: Query): Promise<ChannelModel[]>;
    putAll<K>(values: ChannelModel[], ids: K[]): Promise<ChannelModel[]>;
    putAll(values: ChannelModel[], query): Promise<ChannelModel[]> {
        throw new MethodNotImplementedError();
    }

}
