import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: './public-web/public-web.module#PublicWebModule',
    },
    {
        path: 'private',
        loadChildren: './private-web/private-web.module#PrivateWebModule',
    },
    {
        path: '**',
        redirectTo: '/private/admin/clients',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
