import {Inject, Injectable} from '@angular/core';
import {DeleteInteractor, GetAllInteractor, GetInteractor, ObjectQuery, PutInteractor} from '@mobilejazz/harmony-core';
import {ClientModel, OperationModel} from 'smart-statistics-common';
import { ContextService } from './context.service';

@Injectable()
export class OperationService {

    constructor(
        @Inject('GetInteractor<OperationModel>')
        private getOperationInteractor: GetInteractor<OperationModel>,
        @Inject('GetAllInteractor<OperationModel>')
        private getAllOperationInteractor: GetAllInteractor<OperationModel>,
        @Inject('PutInteractor<OperationModel>')
        private putOperationInteractor: PutInteractor<OperationModel>,
        @Inject('DeleteInteractor<OperationModel>')
        private deleteOperationInteractor: DeleteInteractor,
        @Inject(ContextService)
        private context: ContextService,
    ) {}

    public getAll(): Promise<OperationModel[]> {
        const currentClient = this.context.currentClient.value;
        return this.getAllOperationInteractor.execute(new ObjectQuery({clientId: currentClient.id}));
    }

    public put(operation: OperationModel): Promise<OperationModel> {
        const currentClient = this.context.currentClient.value;
        let client = new ClientModel();
        client.id = currentClient.id;
        operation.client = client;
        delete operation.routes;
        delete operation.channels;
        delete operation.vehicles;
        return this.putOperationInteractor.execute(operation);
    }

    public delete(operation: OperationModel): Promise<void> {
        const currentClient = this.context.currentClient.value;
        return this.deleteOperationInteractor.execute(new ObjectQuery({clientId: currentClient.id, operationId: operation.id}));
    }

}
