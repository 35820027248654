import {Inject, Injectable} from '@angular/core';
import {DeleteInteractor, GetAllInteractor, GetInteractor, ObjectQuery, PutInteractor, VoidQuery} from '@mobilejazz/harmony-core';
import {RouteModel} from 'smart-statistics-common';
import {IdQuery} from "@mobilejazz/harmony-core/dist";

@Injectable()
export class RouteService {

    constructor(
        @Inject('DeleteInteractor<RouteModel>')
        protected deleteRouteInteractor: DeleteInteractor,
        @Inject('GetInteractor<RouteModel>')
        protected getRouteInteractor: GetInteractor<RouteModel>,
        @Inject('GetAllInteractor<RouteModel>')
        protected getAllRouteInteractor: GetAllInteractor<RouteModel>,
        @Inject('PutInteractor<RouteModel>')
        protected putRouteInteractor: PutInteractor<RouteModel>,
    ) {}

    public get(routeId: number, operationId: number): Promise<RouteModel> {
        return this.getRouteInteractor.execute(new ObjectQuery({operationId: operationId, routeId: routeId}));
    }

    public getAll(operationId: number): Promise<RouteModel[]> {
        return this.getAllRouteInteractor.execute(new ObjectQuery({operationId: operationId}));
    }

    public put(route: RouteModel, operationId: number): Promise<RouteModel> {
        return this.putRouteInteractor.execute(route, new IdQuery(operationId));
    }

    public delete(route: RouteModel, operationId: number): Promise<void> {
        return this.deleteRouteInteractor.execute(new ObjectQuery({operationId: operationId, routeId: route.id}));
    }

}
